import { useCallback } from 'react';
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import Sidebar from "../../common/Sidebar";
import MiddleHeader from "../../common/MiddleHeader";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import { useEffect, useState, useRef } from "react";
import NewQuoteIcon from "../../assets/images/newquote.png";
import ExistingIcon from "../../assets/images/archive.png";
import ArchiveIcon from "../../assets/images/archive-icon.png";
import CommonValues, { Logout } from "../../common/utils";
import { Navigate, useNavigate } from "react-router-dom";
import ExistingPolicies from "./ExistingPolicies";
import ArchivedPolicies from "./ArchivedPolicies";
import axios from "axios";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import BackIcon from "../../assets/images/Back Button.svg";
import { useLocation } from "react-router-dom";
import { Toast } from "primereact/toast";
import _ from "lodash";
import BackgroundVideo from '../../common/BackgroundVideo';

export default function PremiumFinance() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [showNewPolicies, setShowNewPolicies] = useState(false);
  const [showExistingPolicies, setShowExistingPolicies] = useState(true);
  const [showArchivedPolicies, setShowArchivedPolicies] = useState(false);
  const [refreshClientloading, setRefreshClientLoading] = useState(false);
  const [clientId, setClientId] = useState("");
  const [clientName, setClientName] = useState("");
  const [search, setSearch] = useState("");
  const [searchClientList, setSearchClientList] = useState<any>([]);
  const [searchClientLoading, setSearchClientLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isProspect, setIsProspect] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState("");
  const [businessAddress, setBusinessAddress] = useState<any>({});
  const [locations, setLocations] = useState([]);
  const [showArchivPolicy, setShowArchivePolicy] = useState(false);
  const [duplicatePolicyLoader, setduplicatePolicyLoader] = useState(false);
  const [clientSelect, setclientSelect] = useState(false);
  const [ams360CustomerLoading, setAms360CustomerLoading] = useState(false);
  const [bpName, setBpName] = useState("");
  const [dbaName, setDbaName] = useState("");
  const [policies, setPolicies] = useState<any>({});
  const [policyNumber, setPolicyNumber] = useState("");
  const [indicativePolicyLoading, setIndicativePolicyLoading] = useState(false);
  const [errorMessageInvalidCreds, setErrorMessageInvalidCreds] = useState("");
  const [effectiveDate, setEffectiveDate] = useState("");
  const [showExistingPolicyForMomentum, setShowExistingPolicyForMomentum] = useState(false);
  const location = useLocation();
  const momentumPolicyId = location.state?.policyid || "";
  const momentumInsuredId = location.state?.insuredid || "";
  const momentumWidgetName = location.state?.widgetName || "";
  const reuseSavedQuoteId = location.state?.savedQuoteId || "";
  const [nowcertsClientLoading, setNowcertsClientLoading] = useState(false);
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }

    if (momentumPolicyId != null && momentumPolicyId != "") {
      setShowExistingPolicyForMomentum(true);
    } else if (momentumInsuredId != null && momentumInsuredId != "") {
      setShowExistingPolicyForMomentum(true);
      getNowCertsClientsById(momentumInsuredId);
    }
    else if(reuseSavedQuoteId !=null && reuseSavedQuoteId !=""){
      setShowExistingPolicyForMomentum(true);
    }
  }, []);
  useEffect(() => {
    let storedData = localStorage.getItem("client");
    let clianttlist = storedData ? JSON.parse(storedData) : null;

    if (clianttlist) {
      setClientName(clianttlist.clientName);
      setClientId(clianttlist.clientId);

      if (CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id) {
        getNowCertsClientsById(clianttlist.clientId);
      } else if (CommonValues.GetTenantId() === process.env.REACT_APP_Vertafore_Tenant_Id) {
        getCustomerDetailsAndPolicyDetailsByCustomerId(clianttlist.clientId, false, null);
      }
    }
  }, [localStorage.getItem("client")]);

  const debouncedFetchResults = useCallback(
    _.debounce((searchQuery:any) => {
      if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
        getNowCertsClients(searchQuery);
      }
      if (CommonValues.GetTenantId() == process.env.REACT_APP_Hawksoft_Tenant_Id) {
        getHawksoftClients(searchQuery);
      }
      if (CommonValues.GetTenantId() == process.env.REACT_APP_Vertafore_Tenant_Id) {
        getVertaforeClients(searchQuery);
      }
    }, 500),
    []
  );
  useEffect(() => {
    if (search.replaceAll(" ", "") == "") {
      return;
    }
    setSearchClientLoading(true);
    if (search) {
      debouncedFetchResults(search);
    }
    return () => {
      debouncedFetchResults.cancel();
    };
  }, [search, debouncedFetchResults]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const onBackButtonClick = () => {
    navigate("/landingPage");
  };
  const onClientSearchChange = (value: any) => {
    if (value.length > 255) {
      return;
    }
    setSearch(value);
  };
  const onKeyClick = (e: any) => {
    if (e.keyCode === 13) {
    }
  };
  const onNewQuoteClick = () => {
    setShowNewPolicies(true);
    setShowExistingPolicies(false);
    setShowArchivedPolicies(false);
    setShowExistingPolicyForMomentum(false);
  };
  const onExistingPolicyClick = () => {
    setShowNewPolicies(false);
    setShowExistingPolicies(true);
    setShowArchivedPolicies(false);
    localStorage.removeItem("IsIndicative");
    localStorage.removeItem("isNewPolicy");

    // setShowExistingPolicyForMomentum(false)
  };
  const onArchivePolicyClick = () => {
    setShowNewPolicies(false);
    setShowExistingPolicies(false);
    setShowArchivedPolicies(true);
    setShowExistingPolicyForMomentum(false);
    localStorage.removeItem("isNewPolicy");
  };

  const getHawksoftClients = (query: string) => {
    var data = {
      SearchValue: query,
    };

    var config = {
      method: "POST",
      url: `${process.env.REACT_APP_HAWKSOFTAPI}/api/Hawksoft/getClients`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
        if (response.data.businessProfiles.length > 0) {
          setBusinessName(response.data.businessProfiles[0].name);
          setBusinessAddress(response.data.addresses[0]);
        }
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the hawksoft client details";
            toast.current.show({ severity: "error", summary: "Error", detail: { errorMessage }, life: 3000 });
          }
        }
        setSearchClientLoading(false);
      });
  };
  const getNowCertsClients = (query: string) => {
    var data = {
      SearchValue: query,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while loading the insureds data.Please try again later.",
              life: 3000,
            });
          }
        }
        setSearchClientLoading(false);
      });
  };
  const getVertaforeClients = (query: string) => {
    const token = CommonValues.GetToken();

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_VERTAFOREAPI}/api/vertafore/customerlist?customer=${query}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status === 400 && error.response.data.Message != undefined && error.response.data.Message != null) {
            // setErrorMessageInvalidCreds(error.response.data.Message);
          } else {
            errorMessage = "Unknown error while loading the customer list";
            toast.current.show({ severity: "error", summary: "Error", detail: { errorMessage }, life: 3000 });
          }
        } else {
          errorMessage = "Unknown error while loading the customer list";
          toast.current.show({ severity: "error", summary: "Error", detail: { errorMessage }, life: 3000 });
        }
        setSearchClientLoading(false);
      });
  };
  const onClientSelect = (client: any) => {
    window.history.replaceState(null, "", window.location.pathname);
    setclientSelect(true);
    setSearch("");
    setInputValue(client.name);
    setClientName(client.name);
    setClientId(client.clientId);

    let clianttlist = {
      clientName: client.name,
      clientId: client.clientId,
    };
    localStorage.setItem("client", JSON.stringify(clianttlist));
    setInputValue("");

    if (
      CommonValues.GetTenantId() == process.env.REACT_APP_Vertafore_Tenant_Id ||
      CommonValues.GetTenantId() == process.env.REACT_APP_Hawksoft_Tenant_Id
    ) {
      let clianttlist = {
        clientName: client.name,
        clientId: client.clientId,
      };
      localStorage.setItem("client", JSON.stringify(clianttlist));
      if (showExistingPolicies) {
        setShowExistingPolicies(false);
        setTimeout(() => {
          setShowExistingPolicies(true);
        }, 10);
      }
      if (showNewPolicies) {
        setShowNewPolicies(false);
        setTimeout(() => {
          setShowNewPolicies(true);
        }, 10);
      }
      if (showArchivedPolicies) {
        setShowArchivedPolicies(false);
        setTimeout(() => {
          setShowArchivedPolicies(true);
        }, 10);
      }
      setClientName(client.name);
      setClientId(client.clientId);
    }
    if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
      let clianttlist = {
        clientName: client.name,
        clientId: client.databaseId,
      };
      localStorage.setItem("client", JSON.stringify(clianttlist));
      if (showExistingPolicies) {
        setShowExistingPolicies(false);
        setTimeout(() => {
          setShowExistingPolicies(true);
        }, 10);
      }
      if (showNewPolicies) {
        setShowNewPolicies(false);
        setTimeout(() => {
          setShowNewPolicies(true);
        }, 10);
      }
      if (showArchivedPolicies) {
        setShowArchivedPolicies(false);
        setTimeout(() => {
          setShowArchivedPolicies(true);
        }, 10);
      }
      setClientName(client.name);
      setClientId(client.databaseId);
    }
  };
  const getNowCertsClientsById = (insuredDatabaseId: string) => {
    // setNowcertsClientLoading(true);
    var data = {
      insuredDatabaseId: insuredDatabaseId,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlistbyId?insuredDatabaseId=${insuredDatabaseId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        let insuredInfo = response.data;
        if (insuredInfo != null && insuredInfo.length > 0) {
          let businessAddress: any = {};
          businessAddress.addressType = "";
          businessAddress.city = insuredInfo[0].city;
          businessAddress.state = insuredInfo[0].stateNameOrAbbreviation;
          businessAddress.zipCode = insuredInfo[0].zipCode;
          businessAddress.line1 = insuredInfo[0].addressLine1;
          businessAddress.line2 = insuredInfo[0].addressLine2;
          businessAddress.country = "USA";
          setBusinessAddress(businessAddress);
          setBusinessName(insuredInfo[0].commercialName);
          setBusinessEmail(insuredInfo[0].eMail);
          setBusinessPhoneNumber(insuredInfo[0].phone);
          setClientName(insuredInfo[0].commercialName);
          setClientId(insuredInfo[0].databaseId);

          let clianttlist = {
            clientName: insuredInfo[0].commercialName,
            clientId: insuredInfo[0].databaseId,
          };
          localStorage.setItem("client", JSON.stringify(clianttlist));
        }
        // setNowcertsClientLoading(false);
      })
      .catch((error: any) => {
        // setNowcertsClientLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while loading the insureds data.Please try again later.",
              life: 3000,
            });
          }
        }
      });
  };
  const getNowCertsPolicyDetails = (policyId: any) => {
    var token = CommonValues.GetToken();
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/policydetails?databaseId=${policyId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        let policyInfo = response.data;

        if (policyInfo != null && policyInfo.length > 0) {
          let nowCertsPolicyData: any = {};
          nowCertsPolicyData.clientId = policyInfo[0].insuredDatabaseId;
          nowCertsPolicyData.businessName = policyInfo[0].insuredCommercialName;
          nowCertsPolicyData.applicationType = policyInfo[0].insuredType;
          nowCertsPolicyData.carrier = policyInfo[0].carrierName;
          nowCertsPolicyData.mgaName = policyInfo[0].mgaName;
          nowCertsPolicyData.effectiveDate = policyInfo[0].effectiveDate;
          nowCertsPolicyData.policyNumber = policyInfo[0].number;
          nowCertsPolicyData.quotedPremium = policyInfo[0].totalPremium;
          nowCertsPolicyData.currentPremium = policyInfo[0].totalPremium;
          nowCertsPolicyData.state = policyInfo[0].status;
          nowCertsPolicyData.term = policyInfo[0].policyTerm;
          nowCertsPolicyData.title = policyInfo[0].lineOfBusinesses[0].lineOfBusinessName;
          nowCertsPolicyData.displayOrder = "0";
          nowCertsPolicyData.policyTitle = policyId;

          if (policyInfo[0].lineOfBusinesses != null || policyInfo[0].lineOfBusinesses.length > 0) {
            let lobArray: any = [];
            lobArray.push({
              calculatedPremium: policyInfo[0].totalPremium,
              code: policyInfo[0].lineOfBusinesses[0].lineOfBusinessName,
              policyNumber: policyInfo[0].number,
              effectiveDate: policyInfo[0].effectiveDate,
            });

            nowCertsPolicyData.linesOfBusiness = lobArray;
          }
          if (policyInfo[0].carrierName != null || policyInfo[0].carrierName != "") {
            nowCertsPolicyData.carrierName = policyInfo[0].carrierName;
          }

          duplicateIndicativePolicyforNowCerts(nowCertsPolicyData);
          const newValues = [];
          newValues[0] = policyInfo[0].totalPremium;

          if (policyInfo[0].insuredDatabaseId != null || policyInfo[0].insuredDatabaseId != "") {
          }

          if (policyInfo[0].carrierName != null || policyInfo[0].carrierName != "") {
          }
        }
        setduplicatePolicyLoader(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the policy details.Please try again later.";
            toast.current.show({ severity: "error", summary: "Error", detail: { errorMessage }, life: 3000 });
          }
        } else {
          errorMessage = "Unknown error while loading the policy details.Please try again later.";
          toast.current.show({ severity: "error", summary: "Error", detail: { errorMessage }, life: 3000 });
        }
      });
  };
  const duplicateIndicativePolicyforNowCerts = (duplicatedata: any) => {
    let newcarrier: any = [];
    let newga: any = [];
    let linesOfBusiness: any = [];
    duplicatedata.linesOfBusiness.forEach((element: any) => {
      linesOfBusiness.push({
        calculatedPremium: element.calculatedPremium,
        code: element.code,
        formType: element.formType,
        id: element.id,
        policyId: element.policyId,
        status: element.status,
        policyNumber: element.policyNumber,
        effectiveDate: element.effectiveDate,
      });
      newcarrier.push({
        description: duplicatedata.carrierName,
      });
      newga.push({
        name: duplicatedata.mgaName,
      });
    });

    let coverages: any = [];

    let data = JSON.stringify({
      Id: null,
      clientId: clientId.toString(),
      businessName: duplicatedata.businessName,
      businessEmail: duplicatedata.businessEmail,
      businessPhoneNumber: duplicatedata.businessPhoneNumber,
      policyTitle: duplicatedata.policyTitle,
      applicationType: duplicatedata.applicationType,
      carrier: newcarrier,
      currentPremium: duplicatedata.currentPremium,
      effectiveDate: duplicatedata.effectiveDate,
      isArchived: duplicatedata.isArchived,
      policyNumber: duplicatedata.policyNumber,
      generalAgency: newga,
      quotedPremium: duplicatedata.quotedPremium,
      state: duplicatedata.state,
      type: duplicatedata.type,
      status: duplicatedata.status,
      term: duplicatedata.term.toString(),
      title: duplicatedata.title,
      displayOrder: duplicatedata.displayOrder,
      coverages: coverages,
      linesOfBusiness: linesOfBusiness,
      locations: locations,
      businessAddress: businessAddress,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/indicativepolicy`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(() => {
        setShowExistingPolicies(false);
        setShowArchivePolicy(false);
        setShowNewPolicies(true);
        setShowExistingPolicyForMomentum(false);

        toast.current.show({
          severity: "success",
          detail: "Policy Duplicated successfully",
          life: 3000,
        });
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while duplicating the policy";
            toast.current.show({ severity: "error", summary: "Error", detail: { errorMessage }, life: 3000 });
          }
        }
      });
  };
  const duplicateIndicativePolicyForHawkSoft = (duplicatedata: any, businessData: any) => {
    let businessAddress: any = {};
    businessAddress.city = businessData[0].address.city;
    businessAddress.state = businessData[0].address.state;
    businessAddress.zipCode = businessData[0].address.zipCode;
    businessAddress.line1 = businessData[0].address.line1;
    businessAddress.line2 = businessData[0].address.line2;
    businessAddress.country = businessData[0].address.country;

    let locations: any = [];
    duplicatedata.locations.forEach((element: any) => {
      locations.push({
        id: element.id,
        addressLine1: element.addressLine1,
        addressLine2: element.addressLine2,
        city: element.city,
        state: element.state,
        country: element.country,
        zipCode: element.zipCode,
      });
    });
    let newcarrier: any = [];
    let newGeneralAgency: any = [];
    let linesOfBusiness: any = [];
    duplicatedata.linesOfBusiness.forEach((element: any) => {
      linesOfBusiness.push({
        calculatedPremium: element.calculatedPremium,
        code: element.code,
        formType: element.formType,
        id: element.id,
        policyId: element.policyId,
        status: element.status,
        policyNumber: duplicatedata.policyNumber,
        effectiveDate: duplicatedata.effectiveDate,
      });
      newcarrier.push({
        description: duplicatedata.carrier,
      });
      newGeneralAgency.push({
        name: duplicatedata.program,
      });
    });
    let coverages: any = [];
    duplicatedata.coverages.forEach((element: any) => {
      coverages.push({
        code: element.code,
        deductibles: element.deductibles,
        description: element.description,
        displayOrder: element.displayOrder,
        id: element.id,
        limits: element.limits,
        percent: element.percent,
        premium: element.premium,
      });
    });
    let data = JSON.stringify({
      Id: null,
      clientId: clientId.toString(),
      businessName: businessData[0].businessName,
      businessEmail: businessEmail,
      businessPhoneNumber: businessPhoneNumber,
      accountNumber: duplicatedata.accountNumber,
      agentCode: duplicatedata.agentCode,
      applicationType: duplicatedata.applicationType,
      billingPlan: duplicatedata.billingPlan,
      carrier: newcarrier,
      generalAgency: newGeneralAgency,
      cancellationDate: duplicatedata.cancellationDate,
      currentPremium: duplicatedata.currentPremium,
      daysLapsed: duplicatedata.daysLapsed,
      effectiveDate: duplicatedata.effectiveDate,
      inceptionDate: duplicatedata.inceptionDate,
      isArchived: duplicatedata.isArchived,
      naic: duplicatedata.naic,
      officeId: duplicatedata.officeId,
      paymentPlan: duplicatedata.paymentPlan,
      policyNumber: duplicatedata.policyNumber,
      program: duplicatedata.program,
      quotedPremium: duplicatedata.quotedPremium,
      rater: duplicatedata.rater,
      source: duplicatedata.source,
      state: duplicatedata.state,
      type: duplicatedata.type,
      status: duplicatedata.status,
      statusDate: duplicatedata.statusDate,
      subStatus: duplicatedata.subStatus,
      term: duplicatedata.term,
      title: duplicatedata.title,
      policyTitle: duplicatedata.title,
      displayOrder: duplicatedata.displayOrder,
      typeName: duplicatedata.typeName,
      coverages: coverages,
      linesOfBusiness: linesOfBusiness,
      locations: locations,
      businessAddress: businessAddress,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/indicativepolicy`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(() => {
        setShowExistingPolicies(false);
        setShowArchivePolicy(false);
        setShowNewPolicies(true);
        setduplicatePolicyLoader(false);
        toast.current.show({
          severity: "success",
          detail: "Policy Duplicated successfully",
          life: 3000,
        });
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while duplicating the policy";
            toast.current.show({ severity: "error", summary: "Error", detail: { errorMessage }, life: 3000 });
          }
        }
      });
  };
  const onDuplicatePolicyClick = (rowData: any, businessData: any) => {
    if (CommonValues.GetTenantId() === process.env.REACT_APP_Hawksoft_Tenant_Id) {
      duplicateIndicativePolicyForHawkSoft(rowData, businessData);
      setduplicatePolicyLoader(true);
    } else if (CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id) {
      setduplicatePolicyLoader(true);
      getNowCertsPolicyDetails(rowData.databaseId);
    } else if (CommonValues.GetTenantId() === process.env.REACT_APP_Vertafore_Tenant_Id) {
      let storedData = localStorage.getItem("client");
      let clianttlist = storedData ? JSON.parse(storedData) : null;
      setduplicatePolicyLoader(true);
      getCustomerDetailsAndPolicyDetailsByCustomerId(clianttlist.clientId, true, rowData);
    }
  };

  const getCustomerDetailsAndPolicyDetailsByCustomerId = (customerId: any, isDuplicateRequested: boolean, duplicatedata: any) => {
    const token = CommonValues.GetToken();
    if (isDuplicateRequested == false) {
      setAms360CustomerLoading(true);
    }

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_VERTAFOREAPI}/api/Vertafore/customerpolicies?customerid=${customerId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setAms360CustomerLoading(false);
        setSearch("");
        let clianttlist = {
          clientId: customerId,
          clientName:
            response.data.customer.Customer.FirmName == "" || response.data.customer.Customer.FirmName == null
              ? response.data.customer.Customer.FirstName + " " + response.data.customer.Customer.LastName
              : response.data.customer.Customer.FirmName,
        };

        localStorage.setItem("client", JSON.stringify(clianttlist));
        let ClientName =
          response.data.customer.Customer.FirmName == "" || response.data.customer.Customer.FirmName == null
            ? response.data.customer.Customer.FirstName + " " + response.data.customer.Customer.LastName
            : response.data.customer.Customer.FirmName;
        setInputValue(ClientName);
        setClientName(ClientName);
        let policyList: any = [];

        response.data.policies.PolicyInfoList.forEach((element: any) => {
          policyList.push({
            policyNumber: element.PolicyNumber,
            displayOrder: 0,
            title: element.PolicyTypeOfBusiness,
            effectiveDate: element.PolicyEffectiveDate,
            quotedPremium: -1,
            id: element.PolicyId,
            clientName: ClientName,
          });
        });

        setBpName(response.data.customer.Customer.FirmName);
        setDbaName(response.data.customer.Customer.DoingBusinessAs);
        let policies = {
          policies: policyList,
        };
        setPolicies(policies);
        let policyDuplicateData: any = {};
        if (isDuplicateRequested == true) {
          setIndicativePolicyLoading(true);
          setBusinessName(response.data.customer.Customer.FirmName);
          setBusinessPhoneNumber(response.data.customer.Customer.FirmPhoneNumber);
          let businessAddress: any = {};
          businessAddress.addressType = "";
          businessAddress.city = response.data.customer.Customer.City;
          businessAddress.state = response.data.customer.Customer.State;
          businessAddress.zipCode = response.data.customer.Customer.ZipCode;
          businessAddress.line1 = response.data.customer.Customer.AddressLine1;
          businessAddress.line2 = response.data.customer.Customer.AddressLine2;
          businessAddress.country = "USA";

          setBusinessAddress(businessAddress);
          setPolicyNumber(duplicatedata.PolicyNumber);
          setEffectiveDate(duplicatedata.PolicyEffectiveDate);
          policyDuplicateData.BusinessAddress = businessAddress;
          policyDuplicateData.BusinessName = response.data.customer.Customer.FirmName;
          policyDuplicateData.PolicyNumber = duplicatedata.policyNumber;
          policyDuplicateData.EffectiveDate = duplicatedata.effectiveDate;
          policyDuplicateData.PolicyId = duplicatedata.id;
          policyDuplicateData.customerId = customerId;
          policyDuplicateData.title = duplicatedata.title;

          getPolicyDetailsVertafore(policyDuplicateData);

          getCoverageCodeMappingForVertaFore(duplicatedata.title, "desc", 0, [], policyDuplicateData);
          toast.current.show({
            severity: "success",
            detail: "Policy Duplicated successfully",
            life: 3000,
          });
        } else {
          setduplicatePolicyLoader(false);
        }
      })
      .catch((error: any) => {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status === 400 && error.response.data.Message != undefined && error.response.data.Message != null) {
            setErrorMessageInvalidCreds(error.response.data.Message);
          } else {
            errorMessage = "Unknown error while loading the vertafore client details";
            toast.current.show({ severity: "error", summary: "Error", detail: { errorMessage }, life: 3000 });
          }
        }
        setAms360CustomerLoading(false);
      });
  };
  const getPolicyDetailsVertafore = (policyDuplicateData: any) => {
    const token = CommonValues.GetToken();

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_VERTAFOREAPI}/api/Vertafore/policy?policyid=${policyDuplicateData.PolicyId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        policyDuplicateData.Term = response.data.Term;

        policyDuplicateData.CurrentPremium = [];
        response.data.CurrentPremium.forEach((prem: any) => {
          policyDuplicateData.CurrentPremium.push(prem);
        });

        policyDuplicateData.CoverageCodes = [];
        policyDuplicateData.Carrier = [];
        policyDuplicateData.GeneralAgency = [];
        response.data.LineOfBusiness.forEach((code: any) => {
          policyDuplicateData.CoverageCodes.push(code);
          policyDuplicateData.Carrier.push({ description: response.data.Carrier });
          policyDuplicateData.GeneralAgency.push({ name: response.data.GeneralAgency });
        });
        duplicateIndicativePolicyForVertafore(policyDuplicateData);
      })
      .catch(() => {
        // setInput1Loading(false);
        // let errorMessage = "";
        // if (error.response != null) {
        //   if (error.response.status == 401) {
        //     CommonValues.Logout();
        //   } else {
        //     errorMessage = "Unknown error while loading the policy details";
        //   }
        // } else {
        //   errorMessage = "Unknown error while loading the policy details";
        // }
      });
  };
  const duplicateIndicativePolicyForVertafore = (policyDuplicateData: any) => {
    let linesOfBusiness: any = [];
    let totalPrem: any = 0;
    policyDuplicateData.CoverageCodes.forEach((item: any, index: any) => {
      linesOfBusiness.push({
        id: 1,
        calculatedPremium: parseFloat(policyDuplicateData.CurrentPremium[index]),
        formType: "",
        policyId: 0,
        code: item,
        status: "Unknown",
        coverages: [],
        policyNumber: policyDuplicateData.PolicyNumber,
        effectiveDate: policyDuplicateData.EffectiveDate,
      });
      totalPrem = totalPrem + parseFloat(policyDuplicateData.CurrentPremium[index]);
    });

    let coverages: any = [];

    let data = JSON.stringify({
      Id: null,
      clientId: policyDuplicateData.customerId.toString(),
      businessName: policyDuplicateData.BusinessName,
      businessEmail: policyDuplicateData.businessEmail,
      businessPhoneNumber: policyDuplicateData.businessPhoneNumber,
      policyTitle: policyDuplicateData.title,
      applicationType: "",
      carrier: policyDuplicateData.Carrier,
      generalAgency: policyDuplicateData.GeneralAgency,
      currentPremium: parseFloat(totalPrem),
      effectiveDate: policyDuplicateData.EffectiveDate,
      isArchived: false,
      policyNumber: policyDuplicateData.PolicyNumber,
      term: policyDuplicateData.Term,
      displayOrder: "0",
      coverages: coverages,
      linesOfBusiness: linesOfBusiness,
      locations: locations,
      businessAddress: policyDuplicateData.BusinessAddress,
      title: policyDuplicateData.title,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/indicativepolicy`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(() => {
        setduplicatePolicyLoader(false);
        setShowExistingPolicies(false);
        setShowArchivePolicy(false);
        setShowNewPolicies(true);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while duplicating the policy";
            toast.current.show({ severity: "error", summary: "Error", detail: { errorMessage }, life: 3000 });
          }
        }
      });
  };
  const getCoverageCodeMappingForVertaFore = (code: any, matchFlag: any, index: any, newValue: any, policyDuplicateData: any) => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/coveragecodesbyhscode?HsCode=${code}&MatchFlag=${matchFlag}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        newValue[index] = response.data;
        setInputValue(newValue);
        policyDuplicateData.hsCode = response.data.hsCode;
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the coverage code details";
            toast.current.show({ severity: "error", summary: "Error", detail: { errorMessage }, life: 3000 });
          }
        } else {
          errorMessage = "Unknown error while loading the coverage code details";
          toast.current.show({ severity: "error", summary: "Error", detail: { errorMessage }, life: 3000 });
        }
      });
  };

  return (
    <div className="new-quote-main-page">
      <Toast ref={toast} />
      <Header pagename="PfNewQuotepage" />
      {/*  */}
      <div className="content-wrapper">
        <BackgroundVideo/>
        <div className="row  wrapper-main-row mx-auto wrapper-below-div ">
          <div className="col-12">
            <MiddleHeader />
          </div>
          <div className="row main-content-row mx-auto">
            <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
              <Sidebar pagename="PfNewQuotepage" />
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 main-content-col">
              <div className="row client-search-row position-relative">
                {window.innerWidth < 767 ? (
                  <div className="col-lg-0 col-md-0  col-sm col-2 my-1 ">
                    <img src={BackIcon} className="show-cursor" height={20} width={20} onClick={onBackButtonClick} />
                  </div>
                ) : null}
                <div className="col-lg-5 col-md-6 col-sm-5 col-10 p-0 my-1 client-search-input-col position-relative mx-auto">
                  <div className="search-bar ">
                    <AtkTextField
                      type="text"
                      id="text"
                      className="search-area "
                      name="clientName"
                      onChange={(e) => {
                        onClientSearchChange(e.target.value);
                      }}
                      value={search}
                      onKeyDown={onKeyClick}
                      placeholder={"Enter client name"}
                    />
                  </div>
                  {search.replaceAll(" ", "").length > 0 && (
                    <div id="client-search" className=" position-absolute  ">
                      {searchClientLoading ? (
                        <div className="d-flex justify-content-center my-2 client-loader-div">
                          <AtkLoader />
                        </div>
                      ) : searchClientList.length == 0 ? (
                        <div style={{ padding: "5px", background: "white" }}>
                          <span style={{ color: "#ddd", marginLeft: "10px" }}>No results</span>
                        </div>
                      ) : (
                        searchClientList.map((item: any) => (
                          <div
                            style={{ cursor: "pointer", width: "100%" }}
                            onClick={() => {
                              onClientSelect(item);
                            }}
                          >
                            <a
                              href="#"
                              style={{
                                color: "black",
                                width: "100%",
                                fontSize: "12px",
                              }}
                            >
                              {item.name}
                            </a>
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>

                <div className="col-lg-7 col-md-6 col-sm-6 col-12 my-1">
                  <div className="row text-10">
                    <div className="col-lg-7 col-md-0 col-sm-0 col-0"></div>
                    <div className="d-flex justify-content-evenly col-lg-5 col-md-12 col-sm-12 col-12">
                      {(momentumPolicyId != null && momentumPolicyId != "") || clientName != "" ? (
                        <>
                          {" "}
                          <div
                            className={
                              showExistingPolicies
                                ? "d-flex flex-column align-items-center text-center show-cursor policy-heading-active"
                                : "d-flex flex-column align-items-center text-center show-cursor"
                            }
                            onClick={onExistingPolicyClick}
                          >
                            <img src={ExistingIcon} height="25px" width="25px" />
                            <span>Existing Policies</span>
                          </div>
                          <div
                            className={
                              showNewPolicies
                                ? "d-flex flex-column align-items-center text-center show-cursor policy-heading-active"
                                : "d-flex flex-column align-items-center text-center show-cursor"
                            }
                            onClick={onNewQuoteClick}
                          >
                            <img src={NewQuoteIcon} className="" height="25px" width="25px" />
                            <span>New Policies</span>
                          </div>
                          <div
                            className={
                              showArchivedPolicies
                                ? "d-flex flex-column align-items-center text-center show-cursor policy-heading-active"
                                : "d-flex flex-column align-items-center text-center show-cursor"
                            }
                            onClick={onArchivePolicyClick}
                          >
                            <img src={ArchiveIcon} height="25px" width="25px" />
                            <span>Archived Policies</span>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {nowcertsClientLoading ? (
                <div className="d-flex align-items-center justify-content-center mt-2">
                  <AtkLoader />
                </div>
              ) : (
                <div className="row p-2 mx-auto">
                  {((momentumPolicyId != null && momentumPolicyId != "") || (momentumInsuredId != null && momentumInsuredId != "") || (reuseSavedQuoteId != null && reuseSavedQuoteId != "")) &&
                    showExistingPolicyForMomentum ? (
                    <div className="col-12">
                      <ExistingPolicies
                        type="0"
                        clientId={clientId}
                        search={search}
                        clientName={clientName}
                        isProspect={isProspect}
                        onDuplicatePolicyClick={onDuplicatePolicyClick}
                        momentumPolicyId={momentumPolicyId}
                        momentumInsuredId={momentumInsuredId}
                        momentumWidgetName={momentumWidgetName}
                        reuseSavedQuoteId={reuseSavedQuoteId}
                      />
                    </div>
                  ) : showExistingPolicies && clientName != "" ? (
                    <div className="col-12">
                      <ExistingPolicies
                        type="0"
                        clientId={clientId}
                        search={search}
                        clientName={clientName}
                        isProspect={isProspect}
                        onDuplicatePolicyClick={onDuplicatePolicyClick}
                      // momentumPolicyId={momentumPolicyId}
                      />
                    </div>
                  ) : null}

                  {showNewPolicies && clientName != "" ? (
                    <div className="col-12">
                      <ExistingPolicies type="1" clientId={clientId} search={search} clientName={clientName} isProspect={isProspect} />
                    </div>
                  ) : null}

                  {showArchivedPolicies && clientName != "" ? (
                    <div className="col-12">
                      <ArchivedPolicies clientId={clientId} clientName={clientName} isProspect={isProspect} />
                    </div>
                  ) : null}
                </div>
              )}

              {clientName == "" && showExistingPolicyForMomentum == false ? (
                <div className="row p-2  no-client-msg">
                  <div className="col-12 text-center ">
                    <h4> No client information available</h4>{" "}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
