import React from 'react';
import AtkTextField from '../../AtlaskitControls/AtkTextField';
import TextField from '@atlaskit/textfield';
import AtkLoader from '../../AtlaskitControls/AtkLoader';
import AtkButton from '../../AtlaskitControls/AtkButton';
import SectionMessage from '@atlaskit/section-message';
import moment from 'moment';
import AtkButtonIconBefore from '../../AtlaskitControls/AtkButtonIconBefore';
import axios from 'axios';
import { OverlayPanel } from 'primereact/overlaypanel';
import CommonValues from "../../common/utils";
import { Toast } from "primereact/toast";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Toggle from '@atlaskit/toggle';
import { RadioButton } from "primereact/radiobutton";

export default function SmartScanDataExtractPage(props: any) {
    const feedbackRef = useRef<OverlayPanel>(null);
    const navigate = useNavigate();
    const toast: any = useRef("");
    const [showConfirmationBox, setShowConfirmationBox] = useState(false);
    const [feedBackData, setFeedBackData] = useState<any>([]);
    const [feedBackError, setFeedBackError] = useState("");
    const [feedbackLoading, setFeedbackLoading] = useState(false);
    let options1 = [
        { value: "Commercial", label: "Commercial" },
        { value: "Personal", label: "Personal" },
    ];

    const onFeedbackClick = (e: any) => {
        feedbackRef.current?.toggle(e);
    };

    const onFeedbackChange = (newFeedback: string) => {
        const feedback = { ...feedBackData, feedback: newFeedback, submissionId: props.submissionId };
        setFeedBackData(feedback);
        if (newFeedback.length == 0 || newFeedback.trim() === "") {
            setFeedBackError("Please enter your feedback");
        } else if (newFeedback.length > 0) {
            setFeedBackError("");
        }
    };
    const onThumbsUpClick = (rowData: any) => {
        let liked: any;
        if (rowData.isLiked === true) {
            liked = null;
        } else {
            liked = true;
        }
        rowData = { ...rowData, isLiked: liked, submissionId: props.submissionId };
        setFeedBackData(rowData);
        onSaveRatings(rowData);
    };

    const onThumbDownClick = (rowData: any) => {
        let liked: any;
        if (rowData.isLiked === false) {
            liked = null;
        } else {
            liked = false;
        }
        rowData = { ...rowData, isLiked: liked, submissionId: props.submissionId };
        setFeedBackData(rowData);
        onSaveRatings(rowData);
    };

    const onHideFeedbackPopup = () => {
        if (feedbackRef.current) {
            feedbackRef.current.hide();
            setFeedBackData([]);
        }
    };

    const onSubmitFeedbackClick = (rowData: any) => {
        if (rowData.feedback == null || rowData.feedback == "") {
            setFeedBackError("Please enter your feedback");
        } else {
            setFeedBackError("");
            onSaveFeedback(rowData);
            setFeedbackLoading(true);
        }
    };

    const onSaveRatings = (rowData: any) => {
        const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
        const token = CommonValues.GetToken();
        let data = {
            TransactionId: rowData.submissionId,
            Feedback: feedbackVal,
            IsLiked: rowData.isLiked,
        };
        let config = {
            method: "post",
            url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/smartscan/isliked`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        };

        axios
            .request(config)
            .then((response) => {
                toast.current.show({ severity: "success", detail: "Feedback submitted successfully", life: 3000 });
                const feedback = { feedback: "", isLiked: rowData.isLiked };
                setFeedbackLoading(false);
                setFeedBackData(feedback);
                onHideFeedbackPopup();
            })
            .catch((error: any) => {
                console.log(error);
                setFeedbackLoading(false);
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({ severity: "error", detail: "Unknown error while submitting ratings", life: 3000 });
                    }
                }
            });
    };

    const onSaveFeedback = (rowData: any) => {
        const token = CommonValues.GetToken();
        const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
        let data = {
            TransactionId: rowData.submissionId,
            Feedback: feedbackVal,
        };

        let config = {
            method: "post",
            url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/smartscan/feedback`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        };

        axios
            .request(config)
            .then((response) => {
                toast.current.show({ severity: "success", detail: "Feedback Submitted successfully", life: 3000 });
                const feedback = { feedback: "", isLiked: null };
                setFeedbackLoading(false);
                setFeedBackData(feedback);
                onHideFeedbackPopup();
            })
            .catch((error: any) => {
                console.log(error);

                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({ severity: "error", detail: "Unknown error while submitting feedback", life: 3000 });
                    }
                }
            });
    };

    const onSubmitClick = () => {
        props.OnSubmit();
    }
    const onOkClick = () => {
        props.onSubmitClick();
    }
    const onCancelClick = () => {
        props.onCancelClick();
    }
    return (
        <>
            <Toast ref={toast} />
            <div className='row'>
                <div className='col-2'>
                    <span className="client-name-span me-2">
                        <b>Insured Information</b>
                    </span>
                </div>
                { new Date(props.createdDate) >= new Date("14/02/2025") ?
                <>
                {props.fromHistory == true ?
                    <>
                        <div className='col-10 d-flex justify-content-end'>
                            <div className="d-flex align-items-center">
                                <RadioButton
                                    inputId="prospect"
                                    name="prospect"
                                    value="Prospect"
                                    checked={props.selectionOptionsList.SelectProspect == true || props.selectionOptionsList.SelectInsured == true}
                                    disabled={true}
                                />
                                <label htmlFor="prospect" className="mx-2">
                                    <b> {props.selectionOptionsList.SelectProspect == true ? "Select Prospect" : "Select Insured"}</b>
                                </label>
                                {props.selectionOptionsList.SelectProspect == true ?
                                    <>
                                        {props.selectionOptionsList.CreateNewProspect == true ?

                                            <div className="p-field-checkbox d-flex align-items-center ">
                                                <label htmlFor="createNew">Create New Prospect</label>
                                                <Toggle
                                                    id="createNew"
                                                    isChecked={true}
                                                    isDisabled={true}
                                                />
                                            </div>
                                            : <>
                                                <label htmlFor="createNew">Existing Prospect</label>
                                                <Toggle
                                                    id="createNew"
                                                    isChecked={true}
                                                    isDisabled={true}
                                                />
                                            </>}
                                    </> : null}

                                {props.selectionOptionsList.SelectInsured == true ?
                                    <>
                                        {props.selectionOptionsList.CreateNewClient == true ?

                                            <div className="p-field-checkbox d-flex align-items-center ">
                                                <label htmlFor="createNew">Create New Insured</label>
                                                <Toggle
                                                    id="createNew"
                                                    isChecked={true}
                                                    isDisabled={true}
                                                />
                                            </div>
                                            : <>
                                                <label htmlFor="createNew">Existing Insured</label>
                                                <Toggle
                                                    id="createNew"
                                                    isChecked={true}
                                                    isDisabled={true}
                                                />
                                            </>}
                                    </>
                                    : null}
                                {props.selectionOptionsList.CreateQuote == true ?
                                    <>
                                        <label htmlFor="createNew">Create Quote</label>
                                        <Toggle
                                            id="createNew"
                                            isChecked={true}
                                            isDisabled={true}
                                        />
                                    </>
                                    : null}
                                {props.selectionOptionsList.CreateNewPolicy == true ?
                                    <>
                                        <label htmlFor="createNew">New Policy</label>
                                        <Toggle
                                            id="createNew"
                                            isChecked={true}
                                            isDisabled={true}
                                        />
                                    </>
                                    : null}
                                {props.selectionOptionsList.ClientName != "" ?
                                    <div className='search-bar flex-column'>
                                        <AtkTextField
                                            type="text"
                                            id="client-name"
                                            value={props.selectionOptionsList.ClientName}
                                            isDisabled={true}
                                        />
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </>
                    : null}
                  </>  :null}
                
            </div>

            <div className="row subpolicy-block ">
                <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2 ${props.insuredFirstNameError.length > 0 ? " validation-div" : ""}`}>
                    <label>First Name</label>
                    <AtkTextField
                        className="input-field"
                        type="text"
                        id="text"
                        value={props.insuredFirstName}
                        placeholder={props.fromHistory == true ? "" : "Enter first name"}
                        onChange={(e: any) => {
                            props.onChangeInsuredFirstName(e.target.value);
                        }}
                        isDisabled={props.fromHistory == true ? true : false}
                    />
                    <span className="text-danger text-12">{props.insuredFirstNameError}</span>
                </div>

                <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2 ${props.insuredLastNameError.length > 0 ? " validation-div" : ""}`}
                >
                    <label>Last Name</label>
                    <TextField
                        placeholder={props.fromHistory == true ? "" : "Enter last name"}
                        className="input-field"
                        type="text"
                        value={props.insuredLastName}
                        onChange={(e: any) => {
                            props.onChangeInsuredLastName(e.target.value);
                        }}
                        isDisabled={props.fromHistory == true ? true : false}
                    />
                    <span className="text-danger text-12">{props.insuredLastNameError}</span>
                </div>

                <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2 ${props.insuredEmailError.length > 0 ? " validation-div" : ""}`}>
                    <label>Email</label>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.insuredEmail}
                        placeholder={props.fromHistory == true ? "" : "Enter email"}
                        onChange={(e: any) => {
                            props.onChangeInsuredEmail(e.target.value);
                        }}
                        isDisabled={props.fromHistory == true ? true : false}
                    />
                    <span className="text-danger text-12">{props.insuredEmailError}</span>
                </div>


                <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.policyNumberError.length > 0 ? " validation-div" : ""}`}>
                    <span>Policy Number</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.policyNumber}
                        placeholder={props.fromHistory == true ? "" : "Enter policy number"}
                        onChange={(e: any) => {
                            props.onChangePolicyNumber(e.target.value);
                        }}
                        isDisabled={props.fromHistory == true ? true : false}
                    />
                    <span className="text-danger text-12">{props.policyNumberError}</span>
                </div>

                <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.policyPremiumError.length > 0 ? " validation-div" : ""}`}
                >
                    <span>Policy Premium ($)</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.policyPremium}
                        placeholder={props.fromHistory == true ? "" : "Enter policy premium"}
                        onChange={(e: any) => {
                            props.onChangePolicyPremium(e.target.value);
                        }}
                        isDisabled={props.fromHistory == true ? true : false}
                    />
                    <span className="text-danger text-12">{props.policyPremiumError}</span>
                </div>

                <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.carrierNameError.length > 0 ? " validation-div" : ""}`}>
                    <span>Carrier Name</span>

                    {props.fromHistory == true ? (
                        <TextField
                            className="input-field"
                            type="text"
                            value={props.carrierName}
                            isDisabled={props.fromHistory == true ? true : false}
                        />
                    ) :
                        <>
                            {props.carrierNameLoading ? (
                                <div className="d-flex justify-content-center align-items-center p-1">
                                    <AtkLoader></AtkLoader>{" "}
                                </div>
                            ) : (
                                <select
                                    style={{
                                        width: "102%",
                                        color: "#5c5a5a",
                                        fontWeight: "500",
                                        height: "32px",
                                    }}
                                    className="input-field"
                                    value={props.carrierName}
                                    onChange={(e: any) => {
                                        props.onChangeCarrierName(e.target.value);
                                    }}
                                >
                                    <option value="" selected>
                                        Select an option
                                    </option>

                                    {props.allCarrierNames.map((name: any) => (
                                        <option key={name} value={name} title={name}>
                                            {name.length > 16 ? name.substring(0, 16) + "..." : name}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </>
                    }
                    <span className="text-danger text-12">{props.carrierNameError}</span>
                </div>

                <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2  ${props.lineOfBusinessError.length > 0 ? " validation-div" : ""}`}
                >
                    <span>Line of Business</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.lineOfBusiness}
                        placeholder={props.fromHistory == true ? "" : "Enter line of business"}
                        onChange={(e: any) => {
                            props.onChangeLineOfBusiness(e.target.value);
                        }}
                        isDisabled={props.fromHistory == true ? true : false}
                    />
                    <span className="text-danger text-12">{props.lineOfBusinessError}</span>
                </div>
                {props.createQuote ? (
                    <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.quoteNumberError.length > 0 ? " validation-div" : ""}`}>
                        <span>Quote Number</span>
                        <TextField
                            className="input-field"
                            type="text"
                            value={props.quoteNumber}
                            placeholder={props.fromHistory == true ? "" : "Enter quote number"}
                            onChange={(e: any) => {
                                props.onChangeQuoteNumber(e.target.value);
                            }}
                            isDisabled={props.fromHistory == true ? true : false}
                        />
                        <span className="text-danger text-12">{props.quoteNumberError}</span>
                    </div>
                ) : null}


                <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.effectiveDateError.length > 0 ? " validation-div" : ""}`}
                >
                    <span>Effective Date</span>

                    <AtkTextField
                        className="input-field"
                        type="date"
                        id="datepicker"
                        value={moment(props.effectiveDate).format("YYYY-MM-DD")}
                        onChange={(e: any) => {
                            props.onChangeEffectiveDate(e.target.value);
                        }}
                        isDisabled={props.fromHistory == true ? true : false}
                    />
                    <span className="text-danger text-12 btisErrorText">{props.effectiveDateError}</span>
                </div>

                <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.expiryDateError.length > 0 ? " validation-div" : ""}`}>
                    <span>Expiration Date</span>

                    <AtkTextField
                        className="input-field"
                        type="date"
                        id="datepicker"
                        value={moment(props.expiryDate).format("YYYY-MM-DD")}
                        onChange={(e: any) => {
                            props.onChangeExpiryDate(e.target.value);
                        }}
                        isDisabled={props.fromHistory == true ? true : false}
                    />
                    <span className="text-danger text-12 btisErrorText">{props.expiryDateError}</span>
                </div>


                {props.documentType == "DecPage" ?
                    <>
                        {props.createProspect == true || props.createInsured == true ?
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <span>Insured Type</span>

                                {props.fromHistory == true ? (
                                    <TextField
                                        className="input-field"
                                        type="text"
                                        value={props.insuredType}
                                        isDisabled={props.fromHistory == true ? true : false}
                                    />
                                ) :
                                    <>
                                        <select
                                            style={{
                                                width: "102%",
                                                color: "#5c5a5a",
                                                fontWeight: "500",
                                                height: "32px",
                                            }}
                                            className="input-field"
                                            value={props.insuredType}
                                            onChange={(e: any) => {
                                                props.onChangeInsuredType(e.target.value);
                                            }}
                                        >
                                            {options1.map((item: any) => (
                                                <option key={item.value} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ))}
                                        </select>
                                    </>
                                }
                            </div>
                            : null}
                    </>
                    : null}
                <div className="col-12 divider"></div>

                <span className="client-name-span me-2">
                    <b>Business Information</b>
                </span>
                <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2${props.businessNameError.length > 0 ? " validation-div" : ""}`}>
                    <span>Business Name</span>
                    <TextField
                        className="input-field"
                        type="text"
                        placeholder={props.fromHistory == true ? "" : "Enter business name"}
                        value={props.businessName}
                        onChange={(e: any) => {
                            props.onChangeBusinessName(e.target.value);
                        }}
                        isDisabled={props.fromHistory == true ? true : false}
                    />
                    <span className="text-danger text-12">{props.businessNameError}</span>
                </div>

                <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.mailingAddressLine1Error.length > 0 ? " validation-div" : ""}`}>
                    <span>Address</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.mailingAddressLine1}
                        placeholder={props.fromHistory == true ? "" : "Enter address"}
                        onChange={(e: any) => {
                            props.onChangeAgencyMailingAddress1(e.target.value);
                        }}
                        isDisabled={props.fromHistory == true ? true : false}
                    />
                    <span className="text-danger text-12">{props.mailingAddressLine1Error}</span>
                </div>

                <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.mailingCityError.length > 0 ? " validation-div" : ""}`}>
                    <span>City</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.mailingCity}
                        placeholder={props.fromHistory == true ? "" : "Enter city"}
                        onChange={(e: any) => {
                            props.onChangeAgencyMailingCity(e.target.value);
                        }}
                        isDisabled={props.fromHistory == true ? true : false}
                    />
                    <span className="text-danger text-12 btisErrorText">{props.mailingCityError}</span>
                </div>

                <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.mailingStateError.length > 0 ? " validation-div" : ""}`}>
                    <span>State</span>
                    {props.fromHistory == true ? (
                        <TextField
                            className="input-field"
                            type="text"
                            value={props.mailingState}
                            isDisabled={props.fromHistory == true ? true : false}
                        />
                    ) :
                        <select
                            style={{
                                width: "102%",
                                color: "#5c5a5a",
                                fontWeight: "500",
                                height: "34px",
                            }}
                            className="input-field"
                            value={props.mailingState}
                            onChange={(e: any) => {
                                props.onChangeAgencyMailingState(e.target.value);
                            }}
                        >
                            <option value="" selected>
                                Select an option
                            </option>

                            {props.Allstate.map((state: any) => (
                                <option key={state.code} value={state.code}>
                                    {state.code + "-" + state.name}
                                </option>
                            ))}
                        </select>
                    }
                    <span className="text-danger text-12 btisErrorText">{props.mailingStateError}</span>
                </div>

                <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.mailingZipCodeError.length > 0 ? " validation-div" : ""}`}>
                    <span>Zip Code</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.mailingZipCode}
                        placeholder={props.fromHistory == true ? "" : "Enter zip code"}
                        onChange={(e: any) => {
                            props.onChangeAgencyMailingZip(e.target.value);
                        }}
                        isDisabled={props.fromHistory == true ? true : false}
                    />
                    <span className="text-danger text-12 btisErrorText">{props.mailingZipCodeError}</span>
                </div>
            </div>

            {props.fromHistory == true ? null :
                <div className="row">
                    <div className="col-12 d-flex align-items-center  justify-content-end my-2">
                        {props.hideSubmitButton ? <AtkButton label="Submit" onClick={onSubmitClick} className="pf-secondary-btn w-auto me-2" /> : null}


                        {props.ncURL != "" && props.ncURL != null ? (
                            <AtkButton
                                label={props.createInsured == true ? "Open Insured" : "Open Prospect"}
                                onClick={props.onOpenClick}
                                className="pf-secondary-btn w-auto me-2 "
                                style={{ marginTop: "7px" }}
                            />
                        ) : null}
                        {props.showUploadAgain ? (
                            <>
                                <AtkButtonIconBefore
                                    label="Upload Again"
                                    onClick={props.uploadAgain}
                                    className="pf-secondary-btn w-auto me-2 "
                                    style={{ marginTop: "7px" }}
                                />

                                <AtkButtonIconBefore
                                    label="Feedback"
                                    onClick={(e: any) => {
                                        onFeedbackClick(e);
                                    }}
                                    className="pf-secondary-btn w-auto me-2 "
                                    style={{ marginTop: "7px" }}
                                />
                            </>
                        ) : null}
                    </div>
                </div>
            }

            <div className="row">
                <div className='col-3'></div>
                <div className='col-6'>
                    {props.showConfirmationBox ? (
                        <SectionMessage appearance="warning">
                            <div className="row">
                                <div className='col-12 text-center'>

                                    <p>
                                        <span className="d-block text-13">
                                            By clicking{" "}
                                            <span className="text-danger">
                                                <b>Submit</b>
                                            </span>
                                            , a new client/quote will be created in NowCerts. Are you sure you want to proceed?
                                        </span>
                                    </p>
                                    <div className="text-center mt-1">
                                        {props.onSubmitLoader ? (
                                            <div className="d-inline-block me-2 ">
                                                <AtkLoader />
                                            </div>
                                        ) : (
                                            <AtkButton
                                                label="OK"
                                                onClick={() => {
                                                    onOkClick();
                                                }}
                                                className="pf-secondary-btn w-auto me-2"
                                            />
                                        )}
                                        <AtkButton
                                            label="Cancel"
                                            onClick={() => {
                                                onCancelClick();
                                            }}
                                            className="pf-primary-btn w-auto"
                                        />
                                    </div>
                                </div>
                            </div>
                        </SectionMessage>
                    ) : null}
                </div>
                <div className='col-3'></div>
            </div>


            <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                <div className="row mx-auto d-flex align-items-center mb-2">
                    <div className="col-12 feedback-header">
                        <h4>Feedback</h4>
                    </div>
                    <div className={"form-group col-12 mb-2 text-12"}>
                        <textarea
                            name="feedback"
                            onChange={(e) => onFeedbackChange(e.target.value)}
                            value={feedBackData.feedback}
                            className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
                            rows={4}
                            placeholder="Enter your feedback here"
                        />
                        <span className="text-danger  text-12 mt-1">{feedBackError}</span>
                    </div>
                    <div className="col-4 d-flex justify-content-evenly">
                        <i
                            className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
                              ${feedBackData.isLiked == true ? " active" : " "}`}
                            aria-hidden="true"
                            onClick={(e) => onThumbsUpClick(feedBackData)}
                        ></i>
                        <i
                            className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                                ${feedBackData.isLiked == false ? " active" : " "}`}
                            aria-hidden="true"
                            onClick={(e) => onThumbDownClick(feedBackData)}
                        ></i>
                    </div>
                    <div className="col-4 text-end p-2 pe-0  ">
                        <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
                    </div>
                    <div className="col-4 text-end p-2  ">
                        {feedbackLoading ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <AtkLoader />
                            </div>
                        ) : (
                            <AtkButton
                                label="Submit"
                                onClick={() => onSubmitFeedbackClick(feedBackData)}
                                className="pf-secondary-btn w-auto "
                            />
                        )}
                    </div>
                </div>
            </OverlayPanel>
        </>
    );
}