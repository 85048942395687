import axios from "axios";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import CommonValues from "../common/utils";
import VehicleSidebar from "./VehicleSidebar";
import Header from "../common/Header";
import Footer from "../common/Footer";
import VehicleMiddleHeader from "./VehicleMiddleHeader";
import feedbackImg from "../assets/images/feedback.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import AtkButton from "../AtlaskitControls/AtkButton";
import PreviewIcon from "../assets/images/ViewDetailsIcon.png";
import { Dialog } from "primereact/dialog";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import BackgroundVideo from "../common/BackgroundVideo";

export default function VehicelHistory() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [vehicleHistoryLoading, setVehicleHistoryLoading] = useState(true);
  const [vehicleHistoryData, setVehicleHistoryData] = useState<any>([]);
  const [submissionId, setSubmissionId] = useState();
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [selectedDataJson, setSelectedJsonData] = useState<any>([]);
  const [responseJson, setResponseJson] = useState<any>([]);
  const [updateInAMS, setUpdateInAMS] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");

  useEffect(() => {
    const token = CommonValues.GetToken();

    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getVehicleHistory();
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const getVehicleHistory = () => {
    setVehicleHistoryLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/vehicle/getvehiclehistory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setVehicleHistoryData(response.data);
        setVehicleHistoryLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting vehicle history",
              life: 3000,
            });
          }
        }
        setVehicleHistoryLoading(false);
      });
  };

  const feedbackTemplate = (rowData: any) => {
    return <img src={feedbackImg} className=" show-cursor" onClick={(e) => onShowFeedbackPopup(e, rowData)} title="Feedback" />;
  };

  const onShowFeedbackPopup = (e: any, rowData: any) => {
    setFeedBackError("");
    const feedback = { ...rowData, feedback: "" };
    setFeedBackData(feedback);
    feedbackRef.current?.toggle(e);
  };

  const onFeedbackChange = (newFeedback: string) => {
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
    const feedback = { ...feedBackData, feedback: newFeedback };
    setFeedBackData(feedback);
  };

  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    setVehicleHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.transactionId === rowData.transactionId ? { ...response, isLiked: liked } : response))
    );
    onSaveRatings(rowData);
  };

  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
    setVehicleHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.transactionId === rowData.transactionId ? { ...response, isLiked: liked } : response))
    );
  };

  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };

  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else if (rowData.feedback.length > 250) {
      setFeedBackError("Please provide your feedback within 250 character");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      setSubmissionId(rowData.submissionId);
      onSaveFeedback(rowData);
    }
  };
  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: rowData.transactionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/vehicle/feedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Feedback Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };

  const onSaveRatings = (rowData: any) => {
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: rowData.transactionId,
      Feedback: feedbackVal,
      IsLiked: rowData.isLiked,
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/vehicle/isliked`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Feedback submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };

  const statusTemplate = (rowData: any) => {
    return rowData.status === "Success" ? (
      <b className="text-success">{rowData.status}</b>
    ) : rowData.status === "Failed" ? (
      <b className="text-danger"> Failed</b>
    ) : rowData.status === "Not Found" ? (
      <b className="text-info">Not Found</b>
    ) : null;
  };

  const onHidePopupClicked = () => {
    setShowPopUp(false);
  };

  const actionTemplate = (rowData: any) => {
    const responseJson = JSON.parse(rowData.responseJson);
    return rowData.status === "Success" && responseJson.VehiclePrefillMaster.length > 0 ? (
      <>
        <img src={PreviewIcon} height={20} onClick={() => onPreviewClick(rowData)} title="Preview" className="show-cursor" />
      </>
    ) : (
      <>
        <span className=" text-12 quote-not-span">Not Available</span>
      </>
    );
  };

  const onPreviewClick = (rowData: any) => {
    setUpdateInAMS(rowData.updatedInAMS);
    setShowPopUp(true);
    setPreviewLoading(true);
    setTimeout(() => {
      setPreviewLoading(false);
    }, 1000);
    const selectedJsonData = JSON.parse(rowData.selectedJsonData);
    const responseJson = JSON.parse(rowData.responseJson);
    setSelectedJsonData(selectedJsonData);
    const updatedResponseJson = responseJson.VehiclePrefillMaster.map((item: any) => {
      return {
        ...item,
        check: false,
      };
    });
    setResponseJson(updatedResponseJson);

    if (rowData.selectedJsonData != null || rowData.selectedJsonData != undefined) {
      let selectedData: any = [];
      selectedData = selectedJsonData;
      selectedData.forEach((selectedDataItem: any) => {
        updatedResponseJson.map((item: any) => {
          if (selectedDataItem.vinNumber == item.VinNumber) {
            item.check = true;
          }
        });
      });
      setResponseJson(updatedResponseJson);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="naics-landing-main-page">
        <Header pagename="naics-classification-history" />
        <div className="content-wrapper ">
          <BackgroundVideo/>
          <div className="row  wrapper-main-row mx-auto wrapper-below-div  ">
            <div className="col-12">
              <VehicleMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <VehicleSidebar pagename="vehicle-history" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">
                  <div className="row">
                        <div className="mb-3 mt-1">
                          <span className="p-input-icon-left w-25">
                            <i className="fa fa-search grid-search-icon" />
                            <AtkTextField
                              type="text"
                              id="txtfirstName"
                              name="globalFilter"
                              onChange={(e) => setGlobalFilter(e.target.value)}
                              value={globalFilter}
                              className="input-field"
                              placeholder="Search..."
                            />
                          </span>
                        </div>
                        {vehicleHistoryLoading ? (
                        <>
                      <div className="d-flex justify-content-center align-items-center mt-2">
                        <AtkLoader />{" "}
                      </div>
                      </>
                    ) : (
                      <div className="col-12  history-table">
                        <DataTable
                          value={vehicleHistoryData}
                          paginator
                          paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                          rows={10}
                          dataKey="id"
                          globalFilter={globalFilter}
                          emptyMessage="No data available."
                        >
                          <Column header="Insured Name" field="insuredName" sortable></Column>
                          <Column
                            header="Submitted Date"
                            field="created"
                            body={(rowData) => {
                              const utcdate = moment.utc(rowData.created);
                              var localDate = moment(utcdate).local();
                              return <span className="">{localDate.format("MM-DD-YYYY HH:mm")} </span>;
                            }}
                            sortable
                          ></Column>
                          <Column header="Status" body={statusTemplate} field="status" sortable></Column>
                          <Column header="Update In AMS" field="updatedInAMS" sortable></Column>
                          <Column header="Action" body={actionTemplate}></Column>
                          <Column header="Feedback" body={feedbackTemplate}></Column>
                        </DataTable>
                        </div>
                         )}
                      </div>
                  

                  <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                    <div className="row mx-auto d-flex align-items-center mb-2">
                      <div className="col-12 feedback-header">
                        <h4>Feedback</h4>
                      </div>
                      <div className={"form-group col-12 mb-2 text-12"}>
                        <textarea
                          name="feedback"
                          onChange={(e) => onFeedbackChange(e.target.value)}
                          value={feedBackData.feedback}
                          className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
                          rows={4}
                          placeholder="Enter your feedback here"
                        />
                        <span className="text-danger  text-12 mt-1">{feedBackError}</span>
                      </div>
                      <div className="col-4 d-flex justify-content-evenly">
                        <i
                          className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
               ${feedBackData.isLiked == true ? " active" : " "}`}
                          aria-hidden="true"
                          onClick={(e) => onThumbsUpClick(feedBackData)}
                        ></i>
                        <i
                          className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                ${feedBackData.isLiked == false ? " active" : " "}`}
                          aria-hidden="true"
                          onClick={(e) => onThumbDownClick(feedBackData)}
                        ></i>
                      </div>
                      <div className="col-4 text-end p-2 pe-0  ">
                        <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
                      </div>
                      <div className="col-4 text-end p-2  ">
                        {submissionId == feedBackData.submissionId && feedbackLoading ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <AtkLoader />
                          </div>
                        ) : (
                          <AtkButton label="Submit" onClick={() => onSubmitFeedbackClick(feedBackData)} className="pf-secondary-btn w-auto " />
                        )}
                      </div>
                    </div>
                  </OverlayPanel>

                  <Dialog header="" visible={showPopUp} style={{ width: "80vw", height: "90vh" }} onHide={onHidePopupClicked} draggable={false}>
                    {previewLoading ? (
                      <div className="d-flex align-items-center justify-content-center overflow-hidden">
                        <AtkLoader />
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-lg-12 col-md-6 col-sm-12 p-0 preview-vehicle-box-height">
                          <div className="row mx-auto">
                            <b>Vehicle data</b>

                            <div className="col-12">
                              <div className="row">
                                <div className="col-4"></div>
                                <div className="col-4"></div>
                                <div className="col-4">
                                  <span className="text-12">Update In AMS</span>
                                  <AtkTextField type="text" id="text" className="" name="clientName" value={updateInAMS} isDisabled={true} />
                                </div>
                              </div>
                            </div>
                            {responseJson.map((item: any, index: any) => (
                              <div className="col-lg-4 col-md-12 col-sm-12 mt-3" key={item.VinNumber}>
                                <div className="pf-company-col">
                                  <div className="row mx-auto ">
                                    <h4 className={item.check ? "vehicles-title" : "pf-setting-title"}>
                                      <input type="checkbox" value="" checked={item.check} className="bundle-checkbox" disabled={true} />
                                      {item.check ? (
                                        <div className="">
                                          <span className="text-12">{"(Selected Data.)"}</span>
                                        </div>
                                      ) : null}
                                    </h4>
                                    <div className="col-4 d-flex align-items-center mt-2">
                                      <label className="form-label">Vin Number</label>
                                    </div>

                                    <div className="col-8 mt-2 ">
                                      <AtkTextField
                                        type="text"
                                        id="vin"
                                        className="input-field"
                                        name={item.VinNumber}
                                        value={item.VinNumber}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="col-4 d-flex align-items-center mt-2">
                                      <label className="form-label">Type</label>
                                    </div>

                                    <div className="col-8 mt-2 ">
                                      <AtkTextField
                                        type="text"
                                        id="type"
                                        className="input-field"
                                        name={item.VehicleType}
                                        value={item.VehicleType}
                                        isDisabled={true}
                                      />
                                    </div>
                                    <div className="col-4 d-flex align-items-center mt-2">
                                      <label className="form-label">Make</label>
                                    </div>

                                    <div className="col-8 mt-2 ">
                                      <AtkTextField
                                        type="text"
                                        id="make"
                                        className="input-field"
                                        name={item.VehicleManufacturer}
                                        value={item.VehicleManufacturer}
                                        isDisabled={true}
                                      />
                                    </div>
                                    <div className="col-12 mt-2"></div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
