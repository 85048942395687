import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../common/Footer";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import CommonValues from "../common/utils";
import axios from "axios";
import { Toast } from "primereact/toast";
import Header from "../common/Header";
import RabbitSignMiddleHeader from "./RabbitSignMiddleHeader";
import RabbitSignSidebar from "./RabbitSignSidebar";
import AtkButtonIconBefore from "../AtlaskitControls/AtkButtonIconBefore";
import { InputSwitch } from "primereact/inputswitch";
import ReactPlayer from "react-player";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { OverlayPanel } from "primereact/overlaypanel";
import AtkButton from "../AtlaskitControls/AtkButton";
import SectionMessage from "@atlaskit/section-message";
import { Dialog } from "primereact/dialog";
import RabbiSignApiKeyImage from "../../src/assets/images/RabbitSign.png";
import BackgroundVideo from "../common/BackgroundVideo";

export default function RabbitSign() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const subscribeRef = useRef<OverlayPanel>(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [currentBalance, setCurrentBalance] = useState("0");
  const [insufficientBalance, setInsufficientBalance] = useState(true);
  const [name, setName] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [selectedAgent, setSelectedAgent] = useState<any>([]);
  const [dataList, setDataList] = useState([]);
  const [rabbitSignSubscribeLoading, setRabbitSignSubscribeLoading] = useState(false);
  const [isRabbitSignSubscribed, setIsRabbitSignSubscribed] = useState(false);
  const [agencyInfoList, setAgencyInfoList] = useState<any>([]);
  const [onSaveLoading, setOnSaveLoading] = useState(false);
  const [onHistoryLoading, setOnHistoryLoading] = useState(false);
  const [selectedAgencyInfoList, setSelectedAgencyInfoList] = useState<any>([]);
  const [transactionId, setTransactionId] = useState("");
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [rabbitSignLoading, setRabbitSignLoading] = useState(false);
  const [isCardAdded, setIsCardAdded] = useState(false);
  const [nameError, setNameError] = useState("");
  const [apiKeyError, setApiKeyError] = useState("");
  const [secretKeyError, setSecretKeyError] = useState("");
  const [agentsError, setAgentsError] = useState("");
  const [showPopMessage, setShowPopMessage] = useState(false);
  const [activeTab, setActiveTab] = useState("howToSetup");

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getCardDetails();
    getAgencyInfoList();
    getRabbitSignHistory();
    getRabbitSignSubscription();
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (showPopMessage) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => document.body.classList.remove("no-scroll");
  }, [showPopMessage]);

  const getCardDetails = () => {
    setCurrentBalanceLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success) {
          setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
          setInsufficientBalance(response.data.currentBalance / 100 <= 0);
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };
  const onApiKeyChanged = (e: any) => {
    setApiKey(e.target.value);
  };
  const onSecretKeyChanged = (e: any) => {
    setSecretKey(e.target.value);
  };
  const onNameChanged = (e: any) => {
    setName(e.target.value);
  };
  const onSubscribeClicked = (e: any) => {
    // setIsRabbitSignSubscribed(!isRabbitSignSubscribed)
    if (subscribeRef.current) {
      subscribeRef.current.toggle(e.originalEvent);
    }
  };
  const agentsDropdownClicked = (e: any) => {
    let agentsInfo: any = e.target.value;
    setSelectedAgent(e.target.value);
    setSelectedAgencyInfoList(agentsInfo);
  };
  const OnEdit = (rowData: any) => {
    setShowUpdateButton(true);
    setTransactionId(rowData.transactionId);
    setName(rowData.name);
    setApiKey(rowData.apiKey);
    setSecretKey(rowData.secretKey);

    const agentsArray = rowData.agentInfoJson.map((item: any, index: any) => ({
      name: item.name,
      agentId: item.agentId,
    }));
    setSelectedAgent(agentsArray);
  };
  const getAgencyInfoList = () => {
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/agencylist?nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        const agencyInfoList: any = [];
        response.data.value.map((item: any) => {
          agencyInfoList.push({
            name: item.firstName + item.lastName,
            agentId: item.id,
          });
        });
        setAgencyInfoList(agencyInfoList);
      })
      .catch((error) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            if (toast.current) {
              toast.current.show({
                severity: "error",
                detail: "Unknown error while loading the agency list. Please try again later.",
                life: 3000,
              });
            }
          }
        }
      });
  };
  const getRabbitSignHistory = () => {
    setOnHistoryLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/RabbitSign/history`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        const formattedData = response.data.responseJson.map((item: any, index: any) => {
          const agentInfoJson = JSON.parse(item.agents);
          return {
            transactionId: item.transactionId,
            name: item.name,
            apiKey: item.apiKey,
            secretKey: item.secretKey,
            agents: agentInfoJson.map((agent: any) => agent.name).join(", "),
            agentInfoJson: agentInfoJson,
          };
        });
        setTimeout(() => {
          setOnHistoryLoading(false);
        }, 2000);
        setDataList(formattedData);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting rabbit sign history", life: 3000 });
          }
        }
        setOnHistoryLoading(false);
      });
  };
  const onSaveClicked = async () => {
    if (validate()) {
      setOnSaveLoading(true);
      var token = CommonValues.GetToken();
      var data = {
        Target: "RABBIT-SIGN",
        name: name,
        apiKey: apiKey,
        secretkey: secretKey,
        agents: JSON.stringify(selectedAgencyInfoList),
      };

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_DataFabric_Url}/api/RabbitSign/insert`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then((response: any) => {
          if ((response.data.status = "Success")) {
            toast.current.show({
              severity: "success",
              detail: "Agents credentials added successfully.",
              life: 3000,
            });
            getRabbitSignHistory();
            addKeyInNowCertsForAgent();
            setOnSaveLoading(false);
          }
          setName("");
          setSecretKey("");
          setApiKey("");
          setSelectedAgent([]);
        })
        .catch((error: any) => {
          console.log(error);
          if (error.response.data.status == 401) {
            CommonValues.Logout(navigate);
          } else if (toast.current) {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while inserting agency info. Please try again later.",
              life: 3000,
            });
          }
          setOnSaveLoading(false);
        });
    }
  };
  const validate = () => {
    let isValid = true;
    setNameError("");
    setApiKeyError("");
    setSecretKeyError("");
    setAgentsError("");
    if (name == null || name == "") {
      isValid = false;
      setNameError("Please enter name.");
    }
    if (apiKey == null || apiKey == "") {
      isValid = false;
      setApiKeyError("Please enter API key.");
    }
    if (secretKey == null || secretKey == "") {
      isValid = false;
      setSecretKeyError("Please enter secret key.");
    }
    if (selectedAgencyInfoList == null || selectedAgencyInfoList == "") {
      isValid = false;
      setAgentsError("Please select agents.");
    }
    return isValid;
  };
  const onDeleteAgencyInfo = (rowData: any) => {
    setName("");
    setSecretKey("");
    setApiKey("");
    setSelectedAgent([]);
    setShowUpdateButton(false);
    setTransactionId(rowData.transactionId);
    const token = CommonValues.GetToken();
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/RabbitSign/isdelete?transactionId=${rowData.transactionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if ((response.data.status = "Success")) {
          toast.current.show({
            severity: "success",
            detail: "Agent credentials deleted successfully.",
            life: 3000,
          });
          getRabbitSignHistory();
        }
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while delete agent credentials.", life: 3000 });
          }
        }
      });
  };
  const onUpdateClick = () => {
    if (validate()) {
      setOnSaveLoading(true);
      var token = CommonValues.GetToken();
      var data = {
        transactionId: transactionId,
        name: name,
        apiKey: apiKey,
        secretkey: secretKey,
        agents: JSON.stringify(selectedAgencyInfoList),
      };

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_DataFabric_Url}/api/RabbitSign/update`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then((response: any) => {
          if ((response.data.status = "Success")) {
            toast.current.show({
              severity: "success",
              detail: "Agents credentials updated successfully.",
              life: 3000,
            });
            getRabbitSignHistory();
            addKeyInNowCertsForAgent();
          }
          setOnSaveLoading(false);
          setName("");
          setSecretKey("");
          setApiKey("");
          setSelectedAgent([]);
          setShowUpdateButton(false);
        })
        .catch((error: any) => {
          console.log(error);
          if (error.response.data.status == 401) {
            CommonValues.Logout(navigate);
          } else if (toast.current) {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while updating agency info. Please try again later.",
              life: 3000,
            });
          }
          setOnSaveLoading(false);
        });
    }
  };
  const addKeyInNowCertsForAgency = () => {
    var data = {
      ApiKey: apiKey,
      secretKey: secretKey,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "POST",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/addKey?nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        toast.current.show({
          severity: "success",
          detail: "Agent key added successfully in NowCerts.",
          life: 3000,
        });
      })
      .catch((error) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            if (toast.current) {
              toast.current.show({
                severity: "error",
                detail: "Unknown error while adding key in NowCerts. Please try again later.",
                life: 3000,
              });
            }
          }
        }
      });
  };

  const addKeyInNowCertsForAgent = () => {
    let agentIdList: any = [];
    selectedAgencyInfoList.map((item: any) => {
      agentIdList.push({ UniqueId: Math.floor(1000 + Math.random() * 9000), agentId: item.agentId });
    });
    var data = {
      ApiKey: apiKey,
      secretKey: secretKey,
      AgentIdList: agentIdList,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "POST",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/addkeyforagent?nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        toast.current.show({
          severity: "success",
          detail: "API key and secret key added successfully in NowCerts.",
          life: 3000,
        });
      })
      .catch((error) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            if (toast.current) {
              toast.current.show({
                severity: "error",
                detail: "Unknown error while adding key in NowCerts for agent. Please try again later.",
                life: 3000,
              });
            }
          }
        }
      });
  };
  const actionBodyTemplate = (rowData: any) => {
    return (
      <>
        <i
          className="fa fa-pencil me-2 text-info text-16 show-cursor"
          title="Edit"
          onClick={() => {
            OnEdit(rowData);
          }}
        />
        <i className="fa fa-trash text-danger text-16 show-cursor" title="Delete" onClick={() => onDeleteAgencyInfo(rowData)} />
      </>
    );
  };
  const handleSkip = () => {
    if (subscribeRef.current) {
      subscribeRef.current.hide();
    }
  };
  const onRabbitSignSubscribedClicked = () => {
    handleSkip();
    setIsRabbitSignSubscribed(!isRabbitSignSubscribed);
    var token = CommonValues.GetToken();
    setRabbitSignSubscribeLoading(true);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/Billing/rabbitsign-subscribe?subscribe=${!isRabbitSignSubscribed}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setRabbitSignSubscribeLoading(false);
        if (response.data.inserted > 0) {
          if (isRabbitSignSubscribed) {
            toast.current.show({
              severity: "success",
              detail: "Rabbitsign subscription deactivated successfully",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "success",
              detail: "Rabbitsign subscription activated successfully",
              life: 3000,
            });
          }
          getRabbitSignSubscription();
        } else {
          setRabbitSignSubscribeLoading(false);
          toast.current.show({
            severity: "warn",
            detail: "Oop's, No payment details found, please provide payment method to subscribe the service.",
            life: 5000,
          });
        }
      })
      .catch((error: any) => {
        setRabbitSignLoading(false);
        setRabbitSignSubscribeLoading(false);

        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while subscribing rabbitsign",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while subscribing rabbitsign",
            life: 3000,
          });
        }
      });
  };
  const getRabbitSignSubscription = () => {
    var token = CommonValues.GetToken();
    setRabbitSignLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/Billing/subscription`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setIsCardAdded(response.data.subscriptionDetails.isCardAdded);
        setIsRabbitSignSubscribed(response.data.subscriptionDetails.isRabbitSignSubscribed);
        setRabbitSignLoading(false);
      })
      .catch((error: any) => {
        setRabbitSignLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting rabbitsign subscription",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting rabbitsign subscription",
            life: 3000,
          });
        }
      });
  };
  const onBuyCreditsButtonClicked = () => {
    navigate("/paymentmethod");
  };
  const onClickHere = (e: any) => {
    setShowPopMessage(true);
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="naics-landing-main-page">
        <Header pagename="naics-landing-page" />
        <div className="content-wrapper ">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <RabbitSignMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <RabbitSignSidebar pagename="rabbitsign-page" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                {/* <div className="row history-search-row d-flex  align-items-center justify-content-end text-12 position-relative">
                  <div className="col-2 text-center balance-span">
                    <label>Current Balance</label>
                    {currentBalanceLoading ? (
                      <span className="balance-spinner d-flex  align-items-center justify-content-center ">
                        <AtkLoader />
                      </span>
                    ) : (
                      <span className=" d-block w-auto ">${currentBalance}</span>
                    )}
                  </div>
                </div> */}
                <div className="col-12 p-2 mt-3">
                  <div className="card  subscription-card">
                    <div className="card-body">
                      <div className="row  d-flex  align-items-center ">
                        <div className="col-6 ">
                          <b className="fs-5">Rabbit Sign</b>
                        </div>
                        <div className="col-6 d-flex  justify-content-end ">
                          {rabbitSignSubscribeLoading ? (
                            <div className="d-flex align-items-center justify-content-center my-2">
                              <AtkLoader />
                            </div>
                          ) : (
                            <InputSwitch className="d-flex align-items-center" checked={isRabbitSignSubscribed} onChange={onSubscribeClicked} />
                          )}
                        </div>

                        <>
                          <div className="col-12">
                            <span className="text-26" style={{ color: "#0e9e82" }}>
                              $10/month
                            </span>
                          </div>
                          <div className="col-12">
                            <span className="text-20 ">Get Unlimited E-Signing</span>
                          </div>
                          <div className="col-12 text-14 mt-1" style={{ textAlign: "justify" }}>
                            <span className="">
                              Quickly send the same document for signature over and over. There is no limit on how many templates you can create and
                              use. Quickly send the same document for signature over and over. There is no limit on how many templates you can create
                              and use.
                            </span>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row  p-2 mt-3">
                  <div className="col-6">
                    <div className="card ">
                      <div className="card-body">
                        <div className="row">
                          <div className="form-group mb-2">
                            <b className="">Access Rabbit sign:</b>
                            <div className="col-12">
                              <span className="text-14">
                                Step 1 : Visit <a href="https://www.rabbitsign.com/">RabbitSign</a> portal to create an account.
                              </span>
                            </div>
                            <div className="col-12">
                              <span className="text-14">Step 2 : Log in to your existing account or create a new one.</span>
                            </div>
                            <b className="">Enable Developer Console:</b>
                            <div className="col-12">
                              <span className="text-14">Step 1 : Navigate to your "My Account" page.</span>
                            </div>
                            <div className="col-12">
                              <span className="text-14">Step 2 : Type "opensesame" into the designated field to activate the developer console.</span>
                            </div>
                            <b className="">Generate API Key:</b>
                            <div className="col-12">
                              <span className="text-14">Step 1 : Click on the "Developer Console" link.</span>
                            </div>
                            <div className="col-12">
                              <span className="text-14 ">Step 2 : Click the button to create a new API key.</span>
                            </div>
                            <b className="">Configure Momentum Toolbox:</b>
                            <div className="col-12">
                              <span className="text-14">Step 1 : Copy the generated API key and secret key.</span>
                            </div>
                            <div className="col-12">
                              <span className="text-14">Step 2 : Open the Momentum Toolbox settings.</span>
                            </div>
                            <div className="col-12">
                              <span className="text-14">Step 3 : Paste the keys into the appropriate fields.</span>
                            </div>
                            <div className="col-12">
                              <span className="text-14">Step 4 : Select the agents you want to assign to the RabbitSign account.</span>
                            </div>
                          </div>
                          {/* <div className="form-group col-lg-1 col-md-1 col-sm-12 mb-2 d-flex align-items-center justify-content-center">
                            <b className="fs-5">OR</b>
                          </div> */}

                          {showPopMessage ? (
                            <>
                              <Dialog
                                visible={showPopMessage}
                                position={"center"}
                                draggable={false}
                                resizable={false}
                                onHide={() => {
                                  setShowPopMessage(false);
                                }}
                              >
                                <b className="fs-5">Access Rabbit sign</b>
                                <div className="col-12">
                                  <span className="">
                                    Step 1 : Visit <a href="https://www.rabbitsign.com/">RabbitSign</a> portal to create an account.
                                  </span>
                                </div>
                                <div className="col-12">
                                  <span className="">Step 2 : Log in to your existing account or create a new one.</span>
                                </div>
                              </Dialog>{" "}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <div className="tabs-container">
                        <div className="tabs-header">
                          <button className={`tab-button ${activeTab === "howToSetup" ? "active" : ""}`} onClick={() => setActiveTab("howToSetup")}>
                            How to setup
                          </button>
                          <button className={`tab-button ${activeTab === "howItWorks" ? "active" : ""}`} onClick={() => setActiveTab("howItWorks")}>
                            How it works
                          </button>
                        </div>
                        <div className="tabs-content">
                          {activeTab === "howItWorks" && (
                            <div>
                              <ReactPlayer
                                className="react-video-dialog"
                                width="auto"
                                height="220px"
                                url={"https://www.youtube.com/watch?v=Yc-o92ZUc2Y&t=208s"}
                                controls={true}
                              />
                            </div>
                          )}
                          {activeTab === "howToSetup" && (
                            <div>
                              <iframe
                                src="https://www.loom.com/embed/3544ff2808e44cfa98a7ab1dbfa454f9?sid=4ffcb361-0588-45b5-af29-548eeb8fa2e5%22"
                                frameBorder="0"
                                allowFullScreen
                                title="Loom Video"
                                style={{ width: "100%", height: "214px", borderRadius: "8px" }}
                              ></iframe>
                              {/* <ReactPlayer
                                className="react-video-dialog"
                                width="100%"
                                height="220px"
                                url={"https://www.loom.com/embed/3544ff2808e44cfa98a7ab1dbfa454f9?sid=4ffcb361-0588-45b5-af29-548eeb8fa2e5%22"}
                                controls={true}
                                onError={(e) => console.error("Error loading video:", e)}
                                onReady={() => console.log("Video is ready to play!")}
                              /> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row  p-2 mt-3">
                  <div className="col-12 ">
                    <div className="card ">
                      <div className="card-body">
                        <div className="row  d-flex  ">
                          <div className="form-group col-lg-12 col-md-2 col-sm-12 mb-4">
                            <p className="fs-5">Already have a RabbitSign account? &nbsp; Enter your credentials here.</p>
                          </div>
                          <div
                            className={
                              nameError.length > 0
                                ? "form-group col-lg-2 col-md-2 col-sm-12 mb-2 validation-div"
                                : "form-group col-lg-2 col-md-2 col-sm-12 mb-2"
                            }
                          >
                            <label>Key Name</label>
                            <span className="text-danger "> *</span>
                            <AtkTextField
                              className="input-field"
                              type="text"
                              id="text"
                              value={name}
                              onChange={onNameChanged}
                              placeholder="Please enter key name"
                            />
                            <span className="text-danger text-12">{nameError}</span>
                          </div>
                          <div
                            className={
                              apiKeyError.length > 0
                                ? "form-group col-lg-3 col-md-3 col-sm-12 mb-2 validation-div"
                                : "form-group col-lg-3 col-md-3 col-sm-12 mb-2"
                            }
                          >
                            <label>API Key</label>
                            <span className="text-danger "> *</span>
                            <AtkTextField
                              className="input-field"
                              type="text"
                              id="text"
                              value={apiKey}
                              onChange={onApiKeyChanged}
                              placeholder="Please enter api key"
                            />
                            <span className="text-danger text-12">{apiKeyError}</span>
                          </div>
                          <div
                            className={
                              secretKeyError.length > 0
                                ? "form-group col-lg-3 col-md-3 col-sm-12 mb-2 validation-div"
                                : "form-group col-lg-3 col-md-3 col-sm-12 mb-2"
                            }
                          >
                            <label>Secret Key</label>
                            <span className="text-danger "> *</span>
                            <AtkTextField
                              className="input-field"
                              type="text"
                              id="text"
                              value={secretKey}
                              onChange={onSecretKeyChanged}
                              placeholder="Please enter secret key"
                            />
                            <span className="text-danger text-12">{secretKeyError}</span>
                          </div>
                          <div
                            className={
                              agentsError.length > 0
                                ? "form-group col-lg-3 col-md-3 col-sm-12 mb-2 validation-div"
                                : "form-group col-lg-3 col-md-3 col-sm-12 mb-2"
                            }
                          >
                            <label>Agents</label>
                            <span className="text-danger "> *</span>
                            <MultiSelect
                              className="finance-company-dropdown input-field"
                              value={selectedAgent}
                              onChange={(e: any) => agentsDropdownClicked(e)}
                              options={agencyInfoList.map((item: any, index: any) => ({ label: item.name, value: item, index: index + 1 }))}
                              placeholder="Select agents"
                            />
                            <span className="text-danger text-12">{agentsError}</span>
                          </div>
                          <div className="form-group col-lg-1 col-md-1 col-sm-12 mt-3">
                            {onSaveLoading ? (
                              <div className="d-flex justify-content-center align-items-center p-2">
                                <AtkLoader></AtkLoader>
                              </div>
                            ) : (
                              <AtkButtonIconBefore
                                label={showUpdateButton ? "Update" : "Add"}
                                // iconBefore={<AtkIcon icon={SAveIcon} />}
                                className="pf-secondary-btn mt-2"
                                onClick={showUpdateButton ? onUpdateClick : onSaveClicked}
                              />
                            )}
                          </div>
                        </div>

                        <div>
                          {onHistoryLoading ? (
                            <div className="d-flex justify-content-center align-items-center p-2">
                              <AtkLoader></AtkLoader>
                            </div>
                          ) : (
                            <div className="row  d-flex  align-items-center history-table mt-4">
                              {/* <div className="mb-3">
                                <span className="p-input-icon-left w-25">
                                  <i className="fa fa-search grid-search-icon" />
                                  <AtkTextField
                                    type="text"
                                    id="txtfirstName"
                                    name="globalFilter"
                                    onChange={(e) => setGlobalFilter(e.target.value)}
                                    value={globalFilter}
                                    className="input-field"
                                    placeholder="Search..."
                                  />
                                </span>
                              </div> */}

                              <DataTable
                                paginator
                                globalFilter={globalFilter}
                                paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                                rows={5}
                                value={dataList}
                                emptyMessage="No data available."
                              >
                                <Column header="Name" field="name" sortable></Column>
                                <Column header="API Key" field="apiKey" sortable></Column>
                                <Column header="Secret Key" field="secretKey" sortable></Column>
                                <Column header="Agents" field="agents" sortable></Column>
                                <Column header="Action" body={actionBodyTemplate}></Column>
                              </DataTable>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      <OverlayPanel ref={subscribeRef} className="menu-panel ">
        {isCardAdded ? (
          <SectionMessage appearance="warning">
            <p>
              <span className=" d-block text-14 text-dark">
                Are you sure you want to {isRabbitSignSubscribed ? "Unsubscribe" : "Subscribe"} Rabbit Sign?
              </span>
            </p>
            <div className="text-end mt-2">
              <AtkButton label="OK" onClick={onRabbitSignSubscribedClicked} className="pf-secondary-btn w-auto me-2" />
              <AtkButton label="Cancel" onClick={handleSkip} className="pf-primary-btn w-auto" />
            </div>
          </SectionMessage>
        ) : (
          <SectionMessage appearance="warning">
            <p>
              <span className=" d-block text-14 text-dark">
                User does not have a payment method. Click OK button to add payment method to subscribe Rabbit Sign.
              </span>
            </p>
            <div className="text-end mt-2">
              <AtkButton label="OK" onClick={onBuyCreditsButtonClicked} className="pf-secondary-btn w-auto me-2" />
              <AtkButton label="Cancel" onClick={handleSkip} className="pf-primary-btn w-auto" />
            </div>
          </SectionMessage>
        )}
      </OverlayPanel>
    </>
  );
}
