import { useEffect, useState, useRef } from "react";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import Header from "../common/Header";
import CommonValues, { Logout } from "../common/utils";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Navigate, useNavigate } from "react-router-dom";
import Footer from "../common/Footer";
import BackgroundVideo from "../common/BackgroundVideo";
const hostname = window.location.hostname;

export default function StartupPage() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    if (hostname === process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2) {
      getMomentumToken();
    } else {
      getNowCertsToken();
    }
    getWizardSetupDetails();
  }, []);

  const getWizardSetupDetails = () => {
    var token = CommonValues.GetToken();

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/getwizardsetup`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        if (response.status == 204) {
          insertWizardDetails();
        } else {
          if (response.data.isWizardSetupDone) {
            navigate("/landingpage");
            localStorage.setItem("isSetupCompleted", "true");
          } else {
            localStorage.setItem("isSetupCompleted", "false");

            navigate("/setupwizard");
          }
        }
      })
      .catch((error: any) => {
        let errorMessage = "";

        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
        }
      });
  };

  const insertWizardDetails = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/insertwizardsetup?IsWizardSetupDone=${false}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        if (response.data.isWizardSetupDone) {
          navigate("/landingpage");
          localStorage.setItem("isSetupCompleted", "true");
        } else {
          navigate("/setupwizard");
          localStorage.setItem("isSetupCompleted", "false");
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
        }
      });
  };
  const getMomentumToken = () => {
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/getmomentumtoken`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.data != "" || response.data != null) {
          localStorage.setItem("NCToken", response.data.access_token);
          localStorage.setItem("NCAgencyId", response.data.agencyId);
        }
      })
      .catch((error) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            if (toast.current) {
              toast.current.show({ severity: "error", detail: "Unknown error while loading the NowCerts Setting details", life: 3000 });
            }
          }
        } else {
          if (toast.current) {
            toast.current.show({ severity: "error", detail: "Unknown error while loading the NowCerts Setting details", life: 3000 });
          }
        }
      });
  };
  const getNowCertsToken = () => {
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/getnowcertstoken`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.data != "" || response.data != null) {
          localStorage.setItem("NCToken", response.data.access_token);
          localStorage.setItem("NCAgencyId", response.data.agencyId);
        }
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the NowCerts Setting details";
          }
        } else {
          errorMessage = "Unknown error while loading the NowCerts Setting details";
        }
      });
  };
  return (
    <div className="startup-page ">
      <Toast ref={toast} />

      <Header />
      <div className="content-wrapper container-fluid landing-content-wrapper d-flex justify-content-center">
                <BackgroundVideo />
        <div className="row mx-auto">
          <div className="col-12 text-center text-light startup-loader">
            <AtkLoader />
            <h3 className="landing-heading-text-color">Initiating an environment for a better experience.</h3>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
