import React, { FC, useEffect, useState, useRef } from "react";
import CapitalPfSettings from "./CapitalPfSettings";
import GotoPfSettings from "./GotoPfSettings";
import IpfsSettings from "./IpfsSettings";
import PeoplesPfSettings from "./PeoplesPfSettings";
import UsPfSettings from "./UsPfSettings";
import FirstInsuranceFundingSettings from "./FirstInsuranceFundingSettings";
import CommonValues from "../common/utils";
import axios from "axios";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import { Toast } from "primereact/toast";
import { Navigate, useNavigate } from "react-router-dom";

export default function FinanceCompanySettings() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [premiumLoading, setPremiumLoading] = useState(false);
  const [setupGotoPFSetting, setSetupGotoPFSetting] = useState(false);
  const [setupCapitalPFSetting, setSetupCapitalPFSetting] = useState(false);
  const [setupIpfsPFSetting, setSetupIpfsPFSetting] = useState(false);
  const [setupPeoplesPFSetting, setSetupPeoplesPFSetting] = useState(false);
  const [setupfirstinsuranceSetting, setSetupFirstInsuranceSetting] = useState(false);
  const [setupUSPFSetting, setSetupUSPFSetting] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(null);
  useEffect(() => {
    getPremiumFinanceSettingsById();
  }, []);

  const getPremiumFinanceSettingsById = () => {
    setPremiumLoading(true);
    var token = CommonValues.GetToken();

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfcompanies`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        const gotoPremiumFinance = response.data.find((item: any) => item.pfServiceId === 1);
        const capitalPremiumFinancing = response.data.find((item: any) => item.pfServiceId === 2);
        const ipfs = response.data.find((item: any) => item.pfServiceId === 4);
        const peoples = response.data.find((item: any) => item.pfServiceId === 5);
        const firstinurancefinance = response.data.find((item: any) => item.pfServiceId === 6);
        const uspremiumfinance = response.data.find((item: any) => item.pfServiceId === 7);
        if (gotoPremiumFinance) {
          setSetupGotoPFSetting(true);
        }
        if (capitalPremiumFinancing) {
          setSetupCapitalPFSetting(true);
        }
        if (ipfs) {
          setSetupIpfsPFSetting(true);
        }
        if (peoples) {
          setSetupPeoplesPFSetting(true);
        }
        if (firstinurancefinance) {
          setSetupFirstInsuranceSetting(true);
        }
        if (uspremiumfinance) {
          setSetupUSPFSetting(true);
        }
        if (gotoPremiumFinance && !selectedCompanyId) {
          setSelectedCompanyId(1);
        } else if (capitalPremiumFinancing && !selectedCompanyId) {
          setSelectedCompanyId(2);
        } else if (ipfs && !selectedCompanyId) {
          setSelectedCompanyId(4);
        } else if (peoples && !selectedCompanyId) {
          setSelectedCompanyId(5);
        } else if (firstinurancefinance && !selectedCompanyId) {
          setSelectedCompanyId(6);
        } else if (uspremiumfinance && !selectedCompanyId) {
          setSelectedCompanyId(7);
        }
        setPremiumLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Premium finance Setting details by id";
          }
        }
        toast.current.show({ severity: "error",  detail: { errorMessage }, life: 3000 });
        setPremiumLoading(false);
      });
  };
  const onCompanyClicked = (companyId: number) => {
    setSelectedCompanyId(companyId);
  };
  return (
    <div className="row mx-auto border rounded  company-setting-setup-mainpage">
      <Toast ref={toast} />
      <div className="col-lg-3 col-md-4 col-sm-4 col-12 company-list-block pt-3">
        {premiumLoading ? (
          <div className="col-12 d-flex justify-content-center align-items-center">
            <AtkLoader />{" "}
          </div>
        ) : (
          <>
            {setupGotoPFSetting ? (
              <div className="row ms-1 company-name-div">
                <div
                  className={selectedCompanyId === 1 ? "col-12 selected-company select-company-hover" : "col-12 not-selected-company select-company-hover select-company-hover"}
                  onClick={() => onCompanyClicked(1)}
                >
                  <span>gotoPremiumFinance</span>
                </div>
              </div>
            ) : null}
            {setupCapitalPFSetting ? (
              <div className="row ms-1 company-name-div">
                <div
                  className={selectedCompanyId === 2 ? "col-12 selected-company select-company-hover" : "col-12 not-selected-company select-company-hover"}
                  onClick={() => onCompanyClicked(2)}
                >
                  <span className="">Capital Premium Financing</span>
                </div>
              </div>
            ) : null}
            {setupIpfsPFSetting ? (
              <>
                <div className="row ms-1 company-name-div">
                  <div
                    className={selectedCompanyId === 4 ? "col-12 selected-company select-company-hover" : "col-12 not-selected-company select-company-hover"}
                    onClick={() => onCompanyClicked(4)}
                  >
                    <span>IPFS</span>
                  </div>
                </div>
              </>
            ) : null}
            {setupPeoplesPFSetting ? (
              <div className="row ms-1 company-name-div">
                <div
                  className={selectedCompanyId === 5 ? "col-12 selected-company select-company-hover" : "col-12 not-selected-company select-company-hover"}
                  onClick={() => onCompanyClicked(5)}
                >
                  <span>Peoples Premium Finance</span>
                </div>
              </div>
            ) : null}
            {setupfirstinsuranceSetting ? (
              <div className="row ms-1 company-name-div">
                <div
                  className={selectedCompanyId === 6 ? "col-12 selected-company select-company-hover" : "col-12 not-selected-company select-company-hover"}
                  onClick={() => onCompanyClicked(6)}
                >
                  <span>FIRST Insurance Funding</span>
                </div>
              </div>
            ) : null}
            {setupUSPFSetting ? (
              <div className="row ms-1 company-name-div ">
                <div
                  className={selectedCompanyId === 7 ? "col-12 selected-company select-company-hover" : "col-12 not-selected-company select-company-hover"}
                  onClick={() => onCompanyClicked(7)}
                >
                  <span>US Premium Finance</span>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
      <div className="col">
        {setupGotoPFSetting && selectedCompanyId === 1 ? <GotoPfSettings /> : null}
        {setupCapitalPFSetting && selectedCompanyId === 2 ? <CapitalPfSettings /> : null}
        {setupIpfsPFSetting && selectedCompanyId === 4 ? <IpfsSettings /> : null}
        {setupPeoplesPFSetting && selectedCompanyId === 5 ? <PeoplesPfSettings /> : null}
        {setupfirstinsuranceSetting && selectedCompanyId === 6 ? <FirstInsuranceFundingSettings /> : null}
        {setupUSPFSetting && selectedCompanyId === 7 ? <UsPfSettings /> : null}
      </div>
    </div>
  );
}
