import React from 'react'
import momentumvideo from ".././assets/video/Purple with pattern.mp4"

export default function BackgroundVideo() {

  return (
    <video className="background-video" autoPlay loop muted>
        <source src={momentumvideo} type="video/mp4" />
      </video>
  )
}
