import { FC, useEffect, useState, useRef } from "react";
import AtkButton from "../AtlaskitControls/AtkButton";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import groupImg from ".././assets/images/Group.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import CommonValues from "../common/utils";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import BackgroundVideo from "../common/BackgroundVideo";

export default function AgentRegistration() {
  const toast: any = useRef("");
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [agencyCode, setAgencyCode] = useState("");
  const [agentEmail, setAgentEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [workEmailError, setWorkEmailError] = useState("");
  const [contactNumberError, setContactNumberError] = useState("");
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [tenantId, setTenantId] = useState("");
  const { index } = useParams<any>();
  const [slugUrl, setSlugUrl] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showAlredyRegisteredUserError, setShowAlredyRegisteredUserError] = useState(false);
  const [showRegisterSuccessMessage, setShowRegisterSuccessMessage] = useState(false);
  const [errorEmailAddress, setErrorEmailAddress] = useState("");

  useEffect(() => {
    if (index) {
      setSlugUrl(index);
    }
    var isPartnerWorking = localStorage.getItem("IsPartnerWorking");
    var email = localStorage.getItem("Partner_Email");
    var firName = localStorage.getItem("Partner_FirstName");
    var lstName = localStorage.getItem("Partner_LastName");
    var contctNumber = localStorage.getItem("Partner_ContactNumber");
    var agency_code = localStorage.getItem("Partner_AgencyCode");
    var agent_email = localStorage.getItem("Partner_AgentEmail");

    if (isPartnerWorking) {
      if (email != null) {
        setWorkEmail(email);
      }
      if (firName != null) {
        setFirstName(firName);
      }
      if (lstName != null) {
        setLastName(lstName);
      }
      if (contctNumber != null) {
        setContactNumber(contctNumber);
      }
      if (agency_code != null) {
        setAgencyCode(agency_code);
      }
      if (agent_email != null) {
        setAgentEmail(agent_email);
      }
      localStorage.removeItem("IsPartnerWorking");
      localStorage.removeItem("Partner_Email");
      localStorage.removeItem("Partner_FirstName");
      localStorage.removeItem("Partner_LastName");
      localStorage.removeItem("Partner_ContactNumber");
      localStorage.removeItem("Partner_AgencyName");
      localStorage.removeItem("Partner_AgencyCode");
      localStorage.removeItem("Partner_AgentEmail");
    }
    getAgencyOnboardingDetails(index);
  }, []);
  const onFirstNameChange = (e: any) => {
    const value = e.target.value;

    if (value.length > 255) {
      return;
    }
    setFirstName(value);
    if (!value.trim()) {
      setFirstNameError("Please enter first name");
    } else {
      setFirstNameError("");
    }
  };
  const onLastNameChange = (e: any) => {
    const value = e.target.value;

    if (value.length > 255) {
      return;
    }
    setLastName(value);
    if (!value.trim()) {
      setLastNameError("Please enter last name");
    } else {
      setLastNameError("");
    }
  };
  const onWorkemailChange = (e: any) => {
    const value = e.target.value;
    const regex =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (value.length > 255) {
      return;
    }

    setWorkEmail(value);
    if (!value.trim()) {
      setWorkEmailError("Please enter work email");
    } else if (regex.test(value) === false) {
      setWorkEmailError("Please enter a valid work email");
    } else {
      setWorkEmailError("");
    }
  };
  const onContactNumberChange = (e: any) => {
    const value = e.target.value;
    const re = /^[+-]?\d*(?:[.,]\d*)?$/;
    if (re.test(value)) {
      if (value.length > 10) {
        return;
      }
      setContactNumber(e.target.value);
    }
    if (value != null && value != "" && re.test(value)) {
      if (value.length < 10 || value.length > 10) {
        setContactNumberError("Please enter valid contact number");
      } else {
        setContactNumberError("");
      }
    } else {
      setContactNumberError("");
    }
  };
  const getAgencyOnboardingDetails = (url: any) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_AUTHAPI}/api/account/agencyonboarding?agencySlugh=${url}`,
      headers: {},
    };
    setDetailsLoading(true);
    axios(config)
      .then((response: any) => {
        setDetailsLoading(false);

        if (response.data.length > 0) {
          setSlugUrl(response.data[0].slugURL);
          setCompanyName(response.data[0].agencyName);
          setCompanyId(response.data[0].companyId);
          setTenantId(response.data[0].tenantId);
        }
      })
      .catch((error) => {
        console.log(error);
        setDetailsLoading(false);

        if (error.response != null && error.response.status == 404) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while getting agency information", life: 3000 });
        }
      });
  };
  const checkValidation = () => {
    let returnValue = true;
    setFirstNameError("");
    setLastNameError("");
    setWorkEmailError("");
    setContactNumberError("");

    if (firstName == null || firstName == "") {
      setFirstNameError("Please enter first name");
      returnValue = false;
    }

    if (lastName == null || lastName == "") {
      setLastNameError("Please enter last name");
      returnValue = false;
    }
    if (workEmail == null || workEmail == "") {
      setWorkEmailError("Please enter work email");
      returnValue = false;
    } else {
      const regex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
      if (regex.test(workEmail) === false) {
        setWorkEmailError("Please enter a valid work email");
        returnValue = false;
      }
    }
    if (contactNumber != null && contactNumber != "") {
      if (contactNumber.length < 10 || contactNumber.length > 10) {
        setContactNumberError("Please enter valid contact number");
        returnValue = false;
      }
    }

    return returnValue;
  };
  const onSubmitClick = () => {
    if (checkValidation()) {
      var data = JSON.stringify({
        FirstName: firstName,
        LastName: lastName,
        ContactNo: contactNumber,
        Email: workEmail,
        CompanyId: companyId,
        SlugURL: slugUrl,
        AcceptTerms: true,
        AgencyCode: agencyCode,
        AgentEmail: agentEmail,
        TenantId: tenantId,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_AUTHAPI}/api/account/agencyonboarding/agent/insert`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      setSubmitLoading(true);

      axios(config)
        .then((response) => {
          setSubmitLoading(false);
          setShowAlredyRegisteredUserError(false);
          setShowRegisterSuccessMessage(true);
        })
        .catch((error) => {
          setSubmitLoading(false);

          if (error.response.status === 404) {
            setShowAlredyRegisteredUserError(true);
            setErrorEmailAddress(workEmail);
          } else {
            setShowAlredyRegisteredUserError(false);
            toast.current.show({
              severity: "error",
              detail: "Unknown error while submitting user details!",
              life: 3000,
            });
          }
        });
    }
  };
  const onLoginLinkClicked = () => {
    navigate("/");
  };
  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      onSubmitClick();
    }
  };
  return (
    <div className="login-page">
      <Toast ref={toast} />

      <Header pagename="agent-register" />
      
      <div className="content-wrapper container-fluid landing-content-wrapper d-flex justify-content-center">
        <BackgroundVideo/>
        <div className="register-box">
          <div className="row register-header mx-auto p-3">
            <div className="col-12 ">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-12 d-flex  align-items-center">
                  <img src={groupImg} className="me-2" />
                  <span className="text-16">
                    <b>Sign up to join {companyName} GigMarket!</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {showAlredyRegisteredUserError ? (
            <div className="row mx-auto ">
              <div className="col-12 already-user-exist-message ">
                <p className="d-flex justify-content-center align-items-center">
                  {errorEmailAddress} already registered. Please <a onClick={onLoginLinkClicked}>login</a>.
                </p>
              </div>
            </div>
          ) : null}
          {showRegisterSuccessMessage ? (
            <div className="row mx-auto  success-box">
              <div className="col-12 agent-success-message">
                <p>Thank you for submitting! InsuranceGIG will be in touch with you shortly about next steps.</p>
              </div>
            </div>
          ) : (
            <div className="row mx-auto d-flex align-items-center px-3 py-4">
              <div className=" col-4 text-start mt-3">
                <label>
                  First Name<span className="text-danger "> *</span>{" "}
                </label>
              </div>
              <div className={firstNameError.length > 0 ? "col-8  validation-div mt-3" : "col-8 mt-3"}>
                <AtkTextField
                  type="text"
                  id="txtfirstName"
                  name="firstName"
                  onChange={onFirstNameChange}
                  value={firstName}
                  className="input-field"
                  placeholder="Enter first name"
                  onKeyDown={onEnterClick}
                />
              </div>
              <div className=" col-4 text-start "></div>
              <div className="col-8 ">
                <span className="text-danger text-12 ">{firstNameError}</span>
              </div>
              <div className=" col-4 text-start mt-3">
                <label>
                  Last Name<span className="text-danger "> *</span>{" "}
                </label>
              </div>
              <div className={lastNameError.length > 0 ? "col-8  validation-div mt-3" : "col-8 mt-3"}>
                <AtkTextField
                  type="text"
                  id="txtLastName"
                  name="lastName"
                  onChange={onLastNameChange}
                  value={lastName}
                  className="input-field"
                  placeholder="Enter last name"
                  onKeyDown={onEnterClick}
                />
              </div>
              <div className=" col-4 text-start "></div>
              <div className="col-8 ">
                <span className="text-danger text-12 ">{lastNameError}</span>
              </div>
              <div className=" col-4 text-start mt-3">
                <label>
                  Work Email<span className="text-danger "> *</span>{" "}
                </label>
              </div>
              <div className={workEmailError.length > 0 ? "col-8  validation-div mt-3" : "col-8 mt-3"}>
                <AtkTextField
                  type="text"
                  id="txtWorkEmail"
                  name="workEmail"
                  onChange={onWorkemailChange}
                  value={workEmail}
                  className="input-field"
                  placeholder="Enter work email"
                  onKeyDown={onEnterClick}
                />
              </div>
              <div className=" col-4 text-start "></div>
              <div className="col-8 ">
                <span className="text-danger text-12 ">{workEmailError}</span>
              </div>
              <div className=" col-4 text-start mt-3 ">
                <label>Contact Number</label>
              </div>
              <div className={contactNumberError.length > 0 ? "col-8  validation-div mt-3" : "col-8 mt-3"}>
                <AtkTextField
                  type="text"
                  id="txtContact"
                  name="contactNumber"
                  onChange={onContactNumberChange}
                  value={contactNumber}
                  className="input-field"
                  placeholder="Enter contact number"
                  onKeyDown={onEnterClick}
                />
              </div>
              <div className=" col-4 text-start "></div>
              <div className="col-8 ">
                <span className="text-danger text-12 ">{contactNumberError}</span>
              </div>

              <div className="col-12 text-center my-3">
                {submitLoading ? (
                  <div className="p-1">
                    <AtkLoader />{" "}
                  </div>
                ) : (
                  <AtkButton label="Submit" onClick={onSubmitClick} className="pf-primary-btn w-auto" />
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
}
