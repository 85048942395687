import SectionMessage from "@atlaskit/section-message";
import { CardCvcElement, CardExpiryElement, CardNumberElement, ElementsConsumer } from "@stripe/react-stripe-js";
import { PaymentMethod } from "@stripe/stripe-js";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import PrefilledCreditCardNumber from "../assets/images/PrefilledCreditCardNumber.svg";
import CommonValues from "../common/utils";

function StripePaymentMethod(props: any) {
  const toast: any = useRef("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [cardDetailsLoading, setCardDetailsLoading] = useState(false);
  const [prefillCardNumber, setPrefillCardNumber] = useState("");
  const [prefillExpiry, setPrefillExpiry] = useState("");
  const [prefillCardCVC, setPrefillCardCVC] = useState("");
  const [currentBalance, setCurrentBalance] = useState("0");
  const [editCardDetailsFlag, setEditCardDetailsFlag] = useState(true);
  const [prefillCardCvcError, setPrefillCardCvcError] = useState("");
  const [prefillExpiryError, setPrefillExpiryError] = useState("");
  const [prefillCardNumberError, setPrefillCardNumberError] = useState("");
  const [isCvcComplete, setIsCvcComplete] = useState(false);
  const [isExpiryComplete, setIsExpiryComplete] = useState(false);
  const [isCardNumberComplete, setIsCardNumberComplete] = useState(false);
  const [isUserCompanyAdmin, setIsUserCompanyAdmin] = useState(true);
  const [paymentMethodDatabaseId, setPaymentMethodDatabaseId] = useState("");
  const [deleteCardDetails, setDeleteCardDetails] = useState(false);

  const { stripe, elements } = props;
  const cardElementOptions = {
    showIcon: true,
    disableLink: false,

    style: {
      base: {
        color: "#676A6C",
        fontSize: "16px",
        fontFamily: "-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Fira Sans','Droid Sans','Helvetica Neue',sans-serif",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "red",
        fontSize: "16px",
      },
    },
  };
  const navigate = useNavigate();

  useEffect(() => {
    getCardDetails();
    getCurrentBalance();
  }, []);

  const getCardDetails = () => {
    setCardDetailsLoading(true);
    setPrefillCardNumberError("");
    setPrefillExpiryError("");
    setPrefillCardCvcError("");
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/card-details`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success) {
          // setCurrentBalance(
          //   "" + response.data.stripeCardDetails.currentBalance / 100
          // );
          if (
            response.data.stripeCardDetails.cardNumber !== null &&
            response.data.stripeCardDetails.expiryMonth !== null &&
            response.data.stripeCardDetails.expiryYear !== null
          ) {
            setPrefillCardNumber("**** ***** **** " + response.data.stripeCardDetails.cardNumber.toString().padStart(4, 0));
            setPrefillExpiry(response.data.stripeCardDetails.expiryMonth + " / " + response.data.stripeCardDetails.expiryYear);
            setPaymentMethodDatabaseId(response.data.stripeCardDetails.stripePaymentMethodDatabaseId);
            setPrefillCardCVC("***");
            setEditCardDetailsFlag(false);
          } else {
            setPrefillCardNumber("");
            setPrefillExpiry("");
            setPaymentMethodDatabaseId("");
            setEditCardDetailsFlag(true);
          }
        } else if (!response.data.success) {
          setIsUserCompanyAdmin(false);
        }
        setCardDetailsLoading(false);
      })
      .catch((error) => {
        setCardDetailsLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting card-details",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting card-details",
            life: 3000,
          });
        }
      });
  };
  const getCurrentBalance = () => {
    setCardDetailsLoading(true);

    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success == true) {
          setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
        }
        setCardDetailsLoading(false);
      })
      .catch((error) => {
        setCardDetailsLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };

  const createCustomer = async (id: any, paymentMethod: PaymentMethod) => {
    var token = CommonValues.GetToken();
    let data = JSON.stringify({
      TokenId: id,
      AutoPay: false,
      PaymentMethodId: paymentMethod.id,
      CardBrand: paymentMethod.card?.brand,
      CardExpMonth: paymentMethod.card?.exp_month,
      CardExpYear: paymentMethod.card?.exp_year,
      CardLast4Digit: paymentMethod.card?.last4,
      PaymentMethodResponseJSON: JSON.stringify(paymentMethod),
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/create`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        if (response.data.success === true) {
          toast.current.show({
            severity: "success",
            detail: "Credit card details saved successfully",
            life: 3000,
          });
          setSubmitLoading(false);
          getCardDetails();
          getCurrentBalance();
        } else {
          toast.current.show({
            severity: "error",
            detail: response.data.errorMessage,
            life: 3000,
          });
          setSubmitLoading(false);
          getCardDetails();
          getCurrentBalance();
        }
      })
      .catch((error: any) => {
        setSubmitLoading(false);
        if (error.response.status === 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error occured while creating stripe customer.",
            life: 3000,
          });
        }
      });
  };

  const createPaymentMethod = async (cardToken: any) => {
    const paymentMethod = await stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      })
      .then(({ paymentMethod }: any) => {
        if (!cardToken.error) {
          createCustomer(cardToken.token.id, paymentMethod);
        }
      })
      .catch((error: any) => {
        setSubmitLoading(false);
        toast.current.show({
          severity: "error",
          detail: "Error creating Payment Method",
          life: 3000,
        });
      });
  };
  const checkValidation = () => {
    let returnValue = true;
    setPrefillCardNumberError("");
    setPrefillExpiryError("");
    setPrefillCardCvcError("");

    if (!isCardNumberComplete) {
      setPrefillCardNumberError("Please enter the 16-digit card number");
      returnValue = false;
    }

    if (!isExpiryComplete) {
      setPrefillExpiryError("Please enter expiration month / year on card");
      returnValue = false;
    }

    if (!isCvcComplete) {
      setPrefillCardCvcError("Please enter the complete 3-digit CVC code on the card.");
      returnValue = false;
    }

    return returnValue;
  };
  const onCardCvcChange = (event: any) => {
    if (event.error) {
      setPrefillCardCvcError(event.error.message);
    }
    if (event.complete) {
      setPrefillCardCvcError("");
      setIsCvcComplete(event.complete);
    }
  };
  const onCardNumberChange = (event: any) => {
    if (event.error) {
      setPrefillCardNumberError(event.error.message);
    }
    if (event.complete) {
      setPrefillCardNumberError("");
      setIsCardNumberComplete(event.complete);
    }
  };
  const onCardExpiryChange = (event: any) => {
    if (event.error) {
      setPrefillExpiryError(event.error.message);
    }
    if (event.complete) {
      setIsExpiryComplete(event.complete);
      setPrefillExpiryError("");
    }
  };
  const handleSaveCardDetails = async () => {
    if (!stripe || !elements) {
      return;
    }
    const cardNumber = elements.getElement(CardNumberElement);
    if (checkValidation()) {
      if (cardNumber) {
        setSubmitLoading(true);
        await stripe
          .createToken(cardNumber)
          .then((response: any) => {
            if (response.error) {
              if (response.error.code.startsWith("invalid_expiry_year")) {
                setPrefillExpiryError(response.error.message);
              }
              if (response.error.code.startsWith("incorrect_number")) {
                setPrefillCardNumberError(response.error.message);
              }
              setSubmitLoading(false);
            } else {
              createPaymentMethod(response);
            }
          })
          .catch((error: any) => {
            setSubmitLoading(false);
          });
      }
    }
  };

  const handleEditCardDetails = () => {
    setEditCardDetailsFlag(true);
  };

  const handleCancelEditingCardDetails = () => {
    setPrefillCardNumberError("");
    setPrefillExpiryError("");
    setPrefillCardCvcError("");
    setEditCardDetailsFlag(false);
  };

  const handleDeleteCardDetails = () => {
    var token = CommonValues.GetToken();

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/deletecard/${paymentMethodDatabaseId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.data.success) {
          toast.current.show({
            severity: "success",
            detail: "Card details deleted successfully.",
            life: 3000,
          });
          getCardDetails();
          getCurrentBalance();
        } else {
          toast.current.show({
            severity: "error",
            detail: response.data.errorMessage,
            life: 3000,
          });
        }
        setDeleteCardDetails(false);
      })
      .catch((error: any) => {
        setDeleteCardDetails(false);
        if (error.response.status === 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error occured while deleting card details.",
            life: 3000,
          });
        }
      });
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="billing-payment-details">
        <div className="row client-search-row ">
          <div className="col-12 d-flex align-items-center justify-content-end">
            <div className="row w-100 d-flex align-items-center justify-content-end">
              <div className="col-2 text-center balance-span">
                <label>Current Balance</label>
                {cardDetailsLoading ? (
                  <span className="balance-spinner d-flex  align-items-center justify-content-center ">
                    <AtkLoader />
                  </span>
                ) : (
                  <span className=" d-block w-auto ">${currentBalance}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="row form-group text-start mt-3">
              <div className="col-md-12 text-16">
                <span>
                  <b>
                    {`${prefillCardNumber.length > 0 && prefillExpiry.length > 0 ? `Update ` : `Add `}`}
                    Payment Method
                  </b>
                </span>
              </div>
            </div>
            {cardDetailsLoading ? (
              <div className="text-center mt-4">
                <AtkLoader />
              </div>
            ) : (
              <>
                {/* <div className="col-lg-12 col-md-12 col-sm-12 mt-3 text-start cred-title">
                  <label>
                    <b>Current Balance</b>
                  </label>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 my-2">
                  <div className="input-group">
                    <span className="input-group-text">$</span>
                    <AtkTextField
                      className="input-field form-control p-0"
                      type="text"
                      id="text"
                      value={currentBalance}
                      isDisabled={true}
                    />
                  </div>
                </div> */}

                {isUserCompanyAdmin ? (
                  <form className="mt-3" id="payment-form">
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-2 text-start cred-title">
                      <label>
                        <b>Card Number</b>
                      </label>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                      {prefillCardNumber.length === 0 || editCardDetailsFlag ? (
                        <CardNumberElement
                          id="card-element"
                          className={` form-control ${prefillCardNumberError.length > 0 ? "stripe-validation" : ""}`}
                          onChange={onCardNumberChange}
                          options={cardElementOptions}
                        />
                      ) : (
                        <AtkTextField
                          type="text"
                          id="cardNumber"
                          value={prefillCardNumber}
                          isDisabled={true}
                          elemAfterInput={<img className="mx-2" src={PrefilledCreditCardNumber}></img>}
                        />
                      )}
                    </div>
                    {prefillCardNumberError.length > 0 || editCardDetailsFlag ? (
                      <div className="text-start m-0 p-0">
                        <span className="text-12 text-danger">{prefillCardNumberError}</span>
                      </div>
                    ) : null}
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-3 text-start cred-title">
                      <label>
                        <b>Card Expiry</b>
                      </label>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                      {prefillExpiry.length === 0 || editCardDetailsFlag ? (
                        <CardExpiryElement
                          id="card-element"
                          className={` form-control ${prefillExpiryError.length > 0 ? "stripe-validation" : ""}`}
                          onChange={onCardExpiryChange}
                          options={cardElementOptions}
                        />
                      ) : (
                        <AtkTextField type="text" id="cardNumber" value={prefillExpiry} isDisabled={true} />
                      )}
                    </div>
                    {prefillExpiryError.length > 0 || editCardDetailsFlag ? (
                      <div className="text-start">
                        <span className="text-12 text-danger">{prefillExpiryError}</span>
                      </div>
                    ) : null}

                    <div className="col-lg-12 col-md-12 col-sm-12 mt-3 text-start cred-title">
                      <label>
                        <b>Card CVC</b>
                      </label>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                      {prefillCardNumber.length === 0 || editCardDetailsFlag ? (
                        <CardCvcElement
                          id="card-element"
                          onChange={onCardCvcChange}
                          className={` form-control ${prefillCardCvcError.length > 0 ? "stripe-validation" : ""}`}
                          options={cardElementOptions}
                        />
                      ) : (
                        <AtkTextField type="text" id="cardCVC" name="prefillCardCVC" value={prefillCardCVC} isDisabled={true} />
                      )}
                    </div>
                    {prefillCardCvcError.length > 0 || editCardDetailsFlag ? (
                      <div className="text-start">
                        <span className="text-12 text-danger">{prefillCardCvcError}</span>
                      </div>
                    ) : null}
                    <div className="row">
                      <div className="col-md-12 text-end mt-3">
                        {editCardDetailsFlag ? (
                          <>
                            <div className="row">
                              <div className="col-md-5"></div>
                              <div className={`col-md-${prefillCardNumber.length > 0 && prefillExpiry.length > 0 ? `4` : `7`} text-end`}>
                                {submitLoading ? (
                                  <AtkLoader />
                                ) : (
                                  <AtkButton
                                    className="pf-primary-btn w-auto mx-2"
                                    label="Save Details"
                                    type="submit"
                                    onClick={handleSaveCardDetails}
                                  ></AtkButton>
                                )}
                              </div>
                              {prefillCardNumber.length > 0 && prefillExpiry.length > 0 ? (
                                <div className="col-md-3">
                                  <AtkButton
                                    className="pf-primary-btn w-auto"
                                    label="Cancel"
                                    type="button"
                                    onClick={handleCancelEditingCardDetails}
                                  ></AtkButton>{" "}
                                </div>
                              ) : null}
                            </div>
                          </>
                        ) : (
                          <>
                            <AtkButton
                              className="pf-primary-btn w-auto mx-2"
                              label="Edit card details"
                              type="button"
                              onClick={handleEditCardDetails}
                            ></AtkButton>

                            <AtkButton
                              appearance="danger"
                              className="pf-danger-btn w-auto"
                              label="Delete card details"
                              type="button"
                              onClick={() => {
                                setDeleteCardDetails(true);
                              }}
                            ></AtkButton>
                            {deleteCardDetails && (
                              <div className="row mt-2">
                                <div className="col-md-1"></div>
                                <div className="col-md-10">
                                  <SectionMessage appearance="warning">
                                    <p>
                                      <span className="d-block text-14 text-dark">Are you sure you want to delete card details?</span>
                                    </p>
                                    <div className="text-center mt-2">
                                      <AtkButton label="OK" onClick={handleDeleteCardDetails} className="pf-secondary-btn w-auto mx-4" />
                                      <AtkButton
                                        appearance="danger"
                                        label="Cancel"
                                        onClick={() => {
                                          setDeleteCardDetails(false);
                                        }}
                                        className="pf-danger-btn w-auto"
                                      />
                                    </div>
                                  </SectionMessage>
                                </div>
                                <div className="col-md-1"></div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                ) : (
                  <div className="mt-4">
                    <AtkMessage title="Please contact company admin." messageText="User does not have permission for adding payment method." />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </>
  );
}

export default function InjectedStripePaymentMethod() {
  return <ElementsConsumer>{({ stripe, elements }) => <StripePaymentMethod stripe={stripe} elements={elements} />}</ElementsConsumer>;
}
