import React from 'react'
import finchImg from "../../src/assets/images/finch.png"

export default function FinchMiddleHeader() {
  return (
    <div className="row middle-header-row ps-3">
      <div className="col-12">
        <div className="middle-header-col active ms-2">
        {/* <img src={finchImg} className="" /> */}

          <img src="https://mintlify.s3.us-west-1.amazonaws.com/finch/logo/logo-black.svg" className="" width="35%"/>
          {/* <span className="middle-header-title">Finch</span> */}

        </div>
      </div>{" "}
    </div>
  )
}
