import axios from "axios";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddApps from "../OtherPages/AddApps";
import Feedback from "../OtherPages/Feedback";
import AppsIcon from "../assets/images/Addapps.png";
import AdminIcon from "../assets/images/AdminSetting.svg";
import HistoryIcon from "../assets/images/History_B.svg";
import HomeIcon from "../assets/images/Home_V.svg";
import IgLogo from "../assets/images/IGLogoIcon.png";
import NewIcon from "../assets/images/NewQuote_B 1.svg";
import SaveIcon from "../assets/images/Saved Quotes_B.svg";
import SettingIcon from "../assets/images/Settings_B.svg";
import FeedbackIcon from "../assets/images/feedback_B.svg";
import HelpIcon from "../assets/images/help.png";
import LogoutIcon from "../assets/images/logout.png";
import MomentumLogo from "../assets/images/MToolbox.svg";
import ProfileIcon from "../assets/images/profile.svg";
import CommonValues from "./utils";
import BillingHeader from "../assets/images/BillingHeader.svg";
import LoginIcon from "../assets/images/logout.svg";
import RegisterIcon from "../assets/images/add-user.svg";
import { useCookies } from "react-cookie";
import {Sidebar} from "primereact/sidebar";
import NCChatBot from "../NCSupportBot/NCChatBot";
import { Guid } from "js-guid";

export default function Header(props: any) {
  const navigate = useNavigate();
  const [settingLoading, setSettingLoading] = useState(false);
  const feedbackRef = useRef<OverlayPanel>(null);
  const appsRef = useRef<OverlayPanel>(null);
  const mobileMenuRef = useRef<OverlayPanel>(null);
  const mobileSettingsMenuRef = useRef<OverlayPanel>(null);
  const [showPrimaryAmsMessage, setShowPrimaryAmsMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast: any = useRef("");
  const location = useLocation();
  const [cookies, removeCookie] = useCookies(["igtoken", "NCToken", "tenantId", "RoleId", "userName"]);
  const hostname = window.location.hostname;
  const [chatBotVisible, setChatBotVisible] = useState(false);

  useEffect(() => {
    let token = CommonValues.GetToken();
    const isSetupCompleted = localStorage.getItem("isSetupCompleted");
    if (token != null && token != "") {
      if (isSetupCompleted === "true" && !window.location.href.includes("bridge")) {
        getCommonSettings();
      }
    }
    if(props.NavigatedFromChatBot){
      setChatBotVisible(true)
    }
  }, []);


  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token != null && token != "") {
      if (
        location.pathname !== "/setupwizard" &&
        location.pathname !== "/setupwizardstart" &&
        location.pathname !== "/" &&
        !window.location.href.includes("bridge") &&
        !location.pathname.includes("setpassword") &&
        !location.pathname.includes("resetpassword")
      ) {
        getWizardSetupDetails();
      }
    }
  }, [location]);
  const getWizardSetupDetails = () => {
    var token = CommonValues.GetToken();
    setLoading(true);

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/getwizardsetup`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setLoading(false);

        if (response.status == 204) {
          navigate("/startuppage");
        } else {
          if (response.data.isWizardSetupDone) {
            localStorage.setItem("isSetupCompleted", "true");
          } else {
            navigate("/setupwizard");
            localStorage.setItem("isSetupCompleted", "false");
          }
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        setLoading(false);

        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
        }
        // toast.current.show({ severity: "error",  detail: { errorMessage }, life: 3000 });
      });
  };
  const getCommonSettings = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfcommonsettings`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    setSettingLoading(true);
    axios(config)
      .then((response: any) => {
        setSettingLoading(false);
        localStorage.setItem("HawksoftSettings", JSON.stringify(response.data));

        if (response.data.pfTenantId == process.env.REACT_APP_NowCerts_Tenant_Id) {
          localStorage.setItem("SelectedAMS", "Nowcert");
          setShowPrimaryAmsMessage(false);
        } else if (response.data.pfTenantId == process.env.REACT_APP_Hawksoft_Tenant_Id) {
          localStorage.setItem("SelectedAMS", "Hawksoft");
          setShowPrimaryAmsMessage(false);
        } else if (response.data.pfTenantId == process.env.REACT_APP_Vertafore_Tenant_Id) {
          localStorage.setItem("SelectedAMS", "Vertafore");
          setShowPrimaryAmsMessage(false);
        }
      })
      .catch((error: any) => {
        setSettingLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
        }
      });
  };

  const onHelpIconClick = () => {
    window.open("https://share.hsforms.com/1I3AKuTHgSiKx3U4_2k6wAgcs5yo", "_blank");
  };
  const onHomeIconClick = () => {
    navigate("/landingpage");
  };
  const onLoginClick = () => {
    navigate("/");
  };
  const onRegisterClick = () => {
    navigate("/agencyonboarding");
  };
  const onLogoutClick = () => {
    navigate("/");
    CommonValues.Logout(navigate);
  };
  const onProfileClick = () => {
    navigate("/userprofile");
  };
  const onBillingClick = () => {
    navigate("/paymentmethod");
  };
  const onCommonSettingsClick = () => {
    navigate("/commonsettings");
  };
  const onLogoClick = () => {
    navigate("/landingpage");
  };
  const onNewQuoteClick = () => {
    navigate("/premiumfinance");
  };
  const onSupportBotClick = () => {
    let conversationId = localStorage.getItem("NCSupportBotConversationId")
    if(!conversationId){
      localStorage.setItem("NCSupportBotConversationId", Guid.newGuid().toString())
    }
    setChatBotVisible(true);
  };
  const onHistoryClick = () => {
    navigate("/pfhistory");
  };
  const onSavedQuotesClick = () => {
    navigate("/pfsavedquotes");
  };
  const onPfSettingClick = () => {
    navigate("/pfsettings");
  };
  const onPrimaryAmsClick = () => {
    navigate("/commonsettings/primaryamssettings");
    if (mobileSettingsMenuRef.current) {
      mobileSettingsMenuRef.current.hide();
    }
  };
  const onAmsAgencySettingsClick = () => {
    navigate("/commonsettings/amsagencysettings");
    if (mobileSettingsMenuRef.current) {
      mobileSettingsMenuRef.current.hide();
    }
  };
  const onGigcodeSettings = () => {
    navigate("/commonsettings/gigcodesettings");
    if (mobileSettingsMenuRef.current) {
      mobileSettingsMenuRef.current.hide();
    }
  };
  const onSupportModeClick = () => {
    navigate("/admin/users");
  };
  const onCloseExtensionClick = () => {
    window.close();
  };

  const handleFeedbackClick = () => {
    window.open("https://survey.hsforms.com/1fQaPwBwzRI-5KQgSHpNV6wcs5yo");
  };
  const handleAddAppsClick = (e: React.MouseEvent) => {
    appsRef.current?.toggle(e);
    if (mobileMenuRef.current) {
      mobileMenuRef.current.hide();
    }
  };
  const handleMobileSettingsMenuClick = (e: React.MouseEvent) => {
    mobileSettingsMenuRef.current?.toggle(e);
    if (mobileMenuRef.current) {
      mobileMenuRef.current.hide();
    }
  };
  const onCloseAdminModeClicked = () => {
    localStorage.setItem("igtoken", cookies.igtoken);
    localStorage.setItem("NCToken", cookies.NCToken);
    localStorage.setItem("tenantId", cookies.tenantId);
    localStorage.setItem("RoleId", cookies.RoleId);
    localStorage.setItem("userName", cookies.userName);

    removeCookie("igtoken", null);
    removeCookie("NCToken", null);
    removeCookie("tenantId", null);
    removeCookie("RoleId", null);
    removeCookie("userName", null);

    navigate("/admin/users");
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="row pf-header-row p-2 mx-auto d-flex align-items-center position-fixed">
        <div className="col-12">
          <div className="row  d-flex align-items-center">
            <div className="col-lg-6 col-md-6  col-sm-6 col-6">
              {props.pagename == "setup-start" || props.pagename == "login" ? (
                <img
                  src={hostname === process.env.REACT_APP_Momentum_Url1 || hostname === process.env.REACT_APP_Momentum_Url2 ? MomentumLogo : IgLogo}
                  className={
                    hostname === process.env.REACT_APP_Momentum_Url1 || hostname === process.env.REACT_APP_Momentum_Url2
                      ? "header-momentum-logo "
                      : "header-ig-logo"
                  }
                />
              ) : (
                <img
                  src={hostname === process.env.REACT_APP_Momentum_Url1 || hostname === process.env.REACT_APP_Momentum_Url2 ? MomentumLogo : IgLogo}
                  className={
                    hostname === process.env.REACT_APP_Momentum_Url1 || hostname === process.env.REACT_APP_Momentum_Url2
                      ? "header-momentum-logo show-cursor"
                      : "header-ig-logo show-cursor"
                  }
                  onClick={onLogoClick}
                />
              )}
            </div>

            <div
              className={
                CommonValues.GetToken() == null
                  ? "col-lg-6 col-md-6  col-sm-6 col-6 d-flex  align-items-center justify-content-end"
                  : "col-lg-6 col-md-6  col-sm-6  col-6 d-flex  align-items-center justify-content-end "
              }
            >
              {CommonValues.GetToken() == null ? (
                <>
                  {props.pagename == "login" || props.pagename == "momentum-sso-page" || props.pagename == "nowcerts-sso-page" ? null : (
                    <span
                      className={location.pathname === "/" ? "header-span display-for-desktop-flex active  " : "header-span display-for-desktop-flex"}
                      onClick={onLoginClick}
                    >
                      <img src={LoginIcon} />
                      <span>Login</span>
                    </span>
                  )}
                  {props.pagename == "register" || props.pagename == "momentum-sso-page" || props.pagename == "nowcerts-sso-page" ? null : (
                    <span
                      className={
                        location.pathname === "/agencyonboarding"
                          ? "header-span display-for-desktop-flex active"
                          : "header-span display-for-desktop-flex"
                      }
                      onClick={onRegisterClick}
                    >
                      <img src={RegisterIcon} />
                      <span>Register</span>
                    </span>
                  )}
                </>
              ) : null}

              {CommonValues.GetToken() == null ||
              props.pagename == "setup-start" ||
              props.pagename == "setpassword" ||
              props.pagename == "resetpassword" ||
              props.pagename == "agent-register" ||
              props.pagename == "register" ? null : (
                <>
                  {localStorage.getItem("RoleId") == "1" ? (
                    <span
                      className={
                        location.pathname === "/admin/users"
                          ? "display-for-desktop-flex  header-span  active "
                          : "header-span display-for-desktop-flex"
                      }
                      onClick={onSupportModeClick}
                    >
                      <img src={AdminIcon} />
                      <span>Admin</span>
                    </span>
                  ) : null}
                  <span className={chatBotVisible ? "display-for-desktop-flex header-span active " : "display-for-desktop-flex header-span"} onClick={onSupportBotClick}>
                    <img src={ProfileIcon} />
                    <span>Chat Bot</span>
                  </span>
                  <span className={location.pathname === "/landingpage" ? "header-span active " : "header-span"} onClick={onHomeIconClick}>
                    <img src={HomeIcon} />
                    <span>Home</span>
                  </span>
                  <span className="header-span display-for-desktop-flex" onClick={onHelpIconClick}>
                    <img src={HelpIcon} />
                    <span>Help</span>
                  </span>
                </>
              )}

              {CommonValues.GetToken() == null ? null : (
                <>
                  {props.pagename == "setup-start" ||
                  props.pagename == "setpassword" ||
                  props.pagename == "resetpassword" ||
                  props.pagename == "agent-register" ||
                  props.pagename == "register" ? null : (
                    <>
                      <span
                        className={
                          location.pathname === "/paymentmethod" ||
                          location.pathname === "/buycredits" ||
                          location.pathname === "/paymenthistory" ||
                          location.pathname === "/mysubscription"
                            ? "header-span display-for-desktop-flex active "
                            : "header-span display-for-desktop-flex"
                        }
                        onClick={onBillingClick}
                      >
                        <img src={BillingHeader} />
                        <span>Billing</span>
                      </span>
                      <span
                        className={
                          location.pathname === "/commonsettings"
                            ? "header-span display-for-desktop-flex active "
                            : "header-span display-for-desktop-flex"
                        }
                        onClick={onCommonSettingsClick}
                      >
                        <img src={SettingIcon} />
                        <span>Settings</span>
                      </span>

                      <span
                        className={
                          location.pathname === "/commonsettings" ||
                          location.pathname === "/commonsettings/primaryamssettings" ||
                          location.pathname === "/commonsettings/amsagencysettings" ||
                          location.pathname === "/commonsettings/gigcodesettings"
                            ? "header-span display-for-mobile-flex active "
                            : "header-span display-for-mobile-flex"
                        }
                        onClick={handleMobileSettingsMenuClick}
                        // onClick={(e: any) => mobileSettingsMenuRef.current?.toggle(e)}
                      >
                        <img src={SettingIcon} />
                        <span>Settings</span>
                      </span>

                      <OverlayPanel ref={mobileSettingsMenuRef} className="menu-panel display-for-mobile">
                        <span className="mobile-view-header-menu " onClick={onPrimaryAmsClick}>
                          <img src={SettingIcon} />
                          <span>Primary AMS</span>
                        </span>
                        <span className="mobile-view-header-menu " onClick={onAmsAgencySettingsClick}>
                          <img src={SettingIcon} />
                          <span>AMS Agency Settings</span>
                        </span>
                        <span className="mobile-view-header-menu " onClick={onGigcodeSettings}>
                          <img src={SettingIcon} />
                          <span>Gigcode Settings</span>
                        </span>
                      </OverlayPanel>
                      <span className=" header-span display-for-desktop-flex" onClick={handleFeedbackClick}>
                        <img src={FeedbackIcon} />
                        <span>Feedback</span>
                      </span>

                      <span className="header-span display-for-desktop-flex" onClick={(e: any) => appsRef.current?.toggle(e)}>
                        <img src={AppsIcon} />
                        <span>Add Apps</span>
                      </span>
                      <span
                        className={
                          location.pathname === "/userprofile"
                            ? "header-span display-for-desktop-flex active "
                            : "header-span display-for-desktop-flex"
                        }
                        onClick={onProfileClick}
                      >
                        <img src={ProfileIcon} />
                        <span>Profile</span>
                      </span>
                    </>
                  )}
                  <span className=" header-span display-for-desktop-flex " onClick={onLogoutClick}>
                    <img src={LogoutIcon} />
                    <span>Logout</span>
                  </span>
                </>
              )}
              {/* {CommonValues.GetToken() == null ? null : ( */}
              {props.pagename == "setup-start" ? (
                <span className="mobile-view-header-menu mb-0  display-for-mobile" onClick={onLogoutClick}>
                  <img src={LogoutIcon} />
                  <span>Logout</span>
                </span>
              ) : CommonValues.GetToken() == null ? (
                <>
                  {props.pagename == "login" || props.pagename == "momentum-sso-page" || props.pagename == "nowcerts-sso-page" ? null : (
                    <span className="mobile-view-header-menu mb-0  display-for-mobile me-2 " onClick={onLoginClick}>
                      <img src={LoginIcon} />
                      <span>Login</span>
                    </span>
                  )}
                  {props.pagename == "register" || props.pagename == "momentum-sso-page" || props.pagename == "nowcerts-sso-page" ? null : (
                    <span className="mobile-view-header-menu mb-0  display-for-mobile " onClick={onRegisterClick}>
                      <img src={RegisterIcon} />
                      <span>Register</span>
                    </span>
                  )}
                </>
              ) : (
                <button
                  className=" display-for-mobile humberger-btn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={(e: any) => mobileMenuRef.current?.toggle(e)}
                >
                  <span className="humberger-icon" style={{ color: "white !important" }}>
                    <i className="fa fa-bars" />
                  </span>
                </button>
              )}
              {/* <span className="show-cursor" onClick={onCloseExtensionClick}>
            <b className="remove-policy-icon " style={{ fontSize: "18px" }}>
              x
            </b>
          </span> */}
              {/* )} */}
              {/* </div> */}
            </div>
          </div>
        </div>
        {cookies.igtoken != null ? (
          <span className="admin-popup">
            You are logged in as a Admin{" "}
            <b className="show-cursor ms-2" onClick={onCloseAdminModeClicked}>
              X
            </b>
          </span>
        ) : null}

        {/* <div className="col-12 d-flex justify-content-center align-items-center">
        <span style={{"backgroundColor":"Highlight"}}>You are logged in as a Admin</span>
        </div> */}
      </div>

      {/* {CommonValues.GetToken() == null ? null : ( */}
      {CommonValues.GetToken() == null ? null : (
        <OverlayPanel ref={mobileMenuRef} className="menu-panel display-for-mobile p-2">
          {CommonValues.GetToken() == null ? (
            <>
              {props.pagename == "login" ? null : (
                <span className="mobile-view-header-menu " onClick={onLoginClick}>
                  <img src={LoginIcon} />
                  <span>Login</span>
                </span>
              )}
              {props.pagename == "register" ? null : (
                <span className="mobile-view-header-menu " onClick={onRegisterClick}>
                  <img src={RegisterIcon} />
                  <span>Register</span>
                </span>
              )}
            </>
          ) : (
            <>
              {props.pagename == "setup-start" ||
              props.pagename == "setpassword" ||
              props.pagename == "resetpassword" ||
              props.pagename == "agent-register" ||
              props.pagename == "register" ? null : (
                <>
                  {showPrimaryAmsMessage ? null : (
                    <>
                      {/* {localStorage.getItem("RoleId") == "1" ? (
                      <span
                        className={location.pathname === "/admin/users" ? "mobile-view-header-menu mb-menu active " : "mobile-view-header-menu"}
                        onClick={onSupportModeClick}
                      >
                        <img src={AdminIcon} />
                        <span>Admin</span>
                      </span>
                    ) : null} */}
                      {props.pagename == "PfNewQuotepage" ||
                      props.pagename == "PfHistorypage" ||
                      props.pagename == "PfSavedQuotespage" ||
                      props.pagename == "PfSettingpage" ? (
                        <>
                          <span
                            className={
                              location.pathname === "/premiumfinance" ? "mobile-view-header-menu mb-menu active " : "mobile-view-header-menu"
                            }
                            onClick={onNewQuoteClick}
                          >
                            <img src={NewIcon} />
                            <span>New Quote</span>
                          </span>

                          <span
                            className={location.pathname === "/pfhistory" ? "mobile-view-header-menu mb-menu active " : "mobile-view-header-menu"}
                            onClick={onHistoryClick}
                          >
                            <img src={HistoryIcon} />
                            <span>History </span>
                          </span>

                          <span
                            className={location.pathname === "/pfsavedquotes" ? "mobile-view-header-menu mb-menu active " : "mobile-view-header-menu"}
                            onClick={onSavedQuotesClick}
                          >
                            <img src={SaveIcon} />
                            <span>Saved Quotes </span>
                          </span>

                          <span
                            className={location.pathname === "/pfsettings" ? "mobile-view-header-menu mb-menu active " : "mobile-view-header-menu"}
                            onClick={onPfSettingClick}
                          >
                            <img src={SettingIcon} />
                            <span>PF Settings </span>
                          </span>
                        </>
                      ) : null}
                    </>
                  )}
                  <span className="mobile-view-header-menu " onClick={handleAddAppsClick}>
                    <img src={AppsIcon} />
                    <span>Add Apps</span>
                  </span>
                  <span className=" mobile-view-header-menu" onClick={handleFeedbackClick}>
                    <img src={FeedbackIcon} />
                    <span>Feedback</span>
                  </span>

                  <span className="mobile-view-header-menu " onClick={onHelpIconClick}>
                    <img src={HelpIcon} />
                    <span>Help</span>
                  </span>
                </>
              )}
              {CommonValues.GetToken() == null ? null : (
                <>
                  {props.pagename == "setup-start" ||
                  props.pagename == "setpassword" ||
                  props.pagename == "resetpassword" ||
                  props.pagename == "agent-register" ||
                  props.pagename == "register" ? null : (
                    <span
                      className={location.pathname === "/userprofile" ? "mobile-view-header-menu mb-menu active " : "mobile-view-header-menu"}
                      onClick={onProfileClick}
                    >
                      <img src={ProfileIcon} />
                      <span>Profile</span>
                    </span>
                    
                  )}
                   <span className="mobile-view-header-menu" onClick={onSupportBotClick}>
                    <img src={ProfileIcon} />
                    <span>Chat Bot</span>
                  </span>
                  <span className="mobile-view-header-menu  " onClick={onLogoutClick}>
                    <img src={LogoutIcon} />
                    <span>Logout</span>
                  </span>
                 
                </>
              )}
            </>
          )}
          {/* </div> */}
        </OverlayPanel>
      )}
      {/* )} */}
      {/* <OverlayPanel ref={feedbackRef} className="feedback-panel">
        <Feedback onSkip={handleSkip} />
      </OverlayPanel> */}

      <OverlayPanel ref={appsRef} className="addapps-panel">
        <AddApps />
      </OverlayPanel>

      
      <Sidebar
        visible={chatBotVisible}
        position="right"
        onHide={() => setChatBotVisible(false)}
        style={{ width: "450px", height: "100%" }}
      >
        <NCChatBot/>
      </Sidebar>
    </>
  );
}
