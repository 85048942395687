import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import BackIcon from "../../assets/images/Back Button.svg";
import FenrisLogo from "../../assets/images/Fenris digital logo.png";
import HistoryIcon from "../../assets/images/History_B.svg";
import NewIcon from "../../assets/images/NewQuote_B 1.svg";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import CommonValues from "../../common/utils";
import ExistingAndFenrisData from "./ExistingAndFenrisData";
import FenrisHistory from "./FenrisHistory";
import { Toast } from "primereact/toast";
import FenrisMiddleHeader from "./FenrisMiddleHeader";
import FenrisSidebar from "./FenrisSidebar";
import AtkButton from "../../AtlaskitControls/AtkButton";
import { InputSwitch } from "primereact/inputswitch";
import FenrisLandingPage from "./FenrisLandingPage";
import Toggle from "@atlaskit/toggle";
import BackgroundVideo from "../../common/BackgroundVideo";

export default function FenrisMainPage(props: any) {
  const toast: any = useRef("");
  const [search, setSearch] = useState("");
  const [searchClientLoading, setSearchClientLoading] = useState(true);
  const [searchClientList, setSearchClientList] = useState<any>([]);
  const navigate = useNavigate();
  const [insuredDatabaseId, setInsuredDataBaseId] = useState("");
  const [existingData, setExistingData] = useState(false);
  const [clientName, setClientName] = useState("");
  const [enrichDataFlag, setEnrichDataFlag] = useState(false);
  const [showFenrisHistory, setShowFenrisHistory] = useState(false);
  const [selectedtab, setSelectedTab] = useState(1);
  const [enrichDataDisbaled, setEnrichDataDisbaled] = useState(true);
  const [searchTextBoxDisbaled, setSearchTextBoxDisbaled] = useState(false);
  const [currentBalance, setCurrentBalance] = useState("0");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [currentBalanceUpdateFlag, setCurrentBalanceUpdateFlag] = useState(false);
  const [existingInsuredSearch, setExistingInsuredSearch] = useState(true);
  const [newInsuredSearch, setNewInsuredSearch] = useState(false);
  const [showFenrisLandingPage, setShowFenrisLadingPage] = useState(false);
  const location = useLocation();
  const momentumPolicyId = location.state?.policyid || "";
  const momentumInsuredDatabaseId = location.state?.insuredid || "";
  const navigatedFromChatBot = location.state?.NAVIGATED_FROM_CHAT_BOT || false;

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
    if (momentumInsuredDatabaseId != null && momentumInsuredDatabaseId != "") {
      getNowCertsClientsById(momentumInsuredDatabaseId);
      setInsuredDataBaseId(momentumInsuredDatabaseId);
      setEnrichDataDisbaled(false);
    }
  }, []);

  useEffect(() => {
    getCardDetails();
  }, [currentBalanceUpdateFlag]);

  const onNewPrefillClick = () => {
    setSearchTextBoxDisbaled(false);
    setEnrichDataDisbaled(true);
    setClientName("");
    setExistingData(false);
    setShowFenrisHistory(false);
  };
  const onBackButtonClick = () => {
    navigate("/landingPage");
    localStorage.removeItem("like");
    localStorage.removeItem("dislike");
  };

  const onClientSearchChange = (value: any) => {
    if (value.length > 255) {
      return;
    }
    setSearch(value);
    setClientName(value);
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
    if (search == "") {
      return;
    }
    setSearchClientLoading(true);
    if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
      const searchTimeout = setTimeout(() => {
        getNowCertsClients(search);
      }, 10);
      return () => clearTimeout(searchTimeout);
    }
  }, [search]);

  const getCardDetails = () => {
    setCurrentBalanceLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success == true) {
          setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };

  const getNowCertsClientsById = (insuredDatabaseId: string) => {
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var data = {
      insuredDatabaseId: insuredDatabaseId,
    };
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlistbyId?insuredDatabaseId=${insuredDatabaseId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        let insuredInfo = response.data;
        setClientName(insuredInfo[0].commercialName);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Error while getting insured data.",
              life: 3000,
            });
          }
        }
      });
  };
  const getNowCertsClients = (query: string) => {
    var data = {
      SearchValue: query,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while loading the insureds data.Please try again later.",
              life: 3000,
            });
          }
        }
        // setErrorMessage(errorMessage);
        setSearchClientLoading(false);
      });
  };

  const onClientSelect = (client: any) => {
    let insuredDatabaseId = client.databaseId;
    setInsuredDataBaseId(insuredDatabaseId);
    setSearch("");
    setClientName(client.name);
    setEnrichDataDisbaled(false);
    localStorage.setItem("like", "false");
    localStorage.setItem("dislike", "false");
  };

  const onClickEnrich = () => {
    // setLoading(true);
    // setTimeout(() => setLoading(false), 1000);
    setExistingData(true);
    setEnrichDataFlag(!enrichDataFlag);
  };

  const onFenrisHistoryClick = () => {
    setClientName("");
    setShowFenrisHistory(true);
    setExistingData(false);
    setSearchTextBoxDisbaled(true);
    setEnrichDataDisbaled(true);
    localStorage.removeItem("like");
    localStorage.removeItem("dislike");
  };

  const onTabSelect = (index: any) => {
    if (index == 1) {
      setSelectedTab(index);
      onNewPrefillClick();
    } else {
      onFenrisHistoryClick();
      setSelectedTab(index);
    }
  };

  const clientNameSet = (clientName: any, textDisbaled: boolean, enrichBuutonDisbaled: boolean) => {
    setClientName(clientName);
    setSearchTextBoxDisbaled(textDisbaled);
    setEnrichDataDisbaled(enrichBuutonDisbaled);
  };
  const enrichRatingDataButtonDisbaled = () => {
    setEnrichDataDisbaled(true);
  };

  const updateCurrentBalanceUpdateFlag = () => {
    setCurrentBalanceUpdateFlag(!currentBalanceUpdateFlag);
  };

  const balanceCheck = () => {
    getCardDetails();
  };

  const handleNewInsuredSearchToggle = () => {
    setNewInsuredSearch(!newInsuredSearch);
    setExistingInsuredSearch(!existingInsuredSearch);
  };

  const handleExistingInsuredSearchToggle = () => {
    setNewInsuredSearch(!newInsuredSearch);
    setExistingInsuredSearch(!existingInsuredSearch);
  };

  const onNextButtonClick = () => {
    if (newInsuredSearch) {
      navigate("/searchnew");
    } else {
      navigate("/searchexisting", { state: { newInsuredSearch, existingInsuredSearch } });
    }
  };
  const onBackClick = () => {
    setShowFenrisLadingPage(false);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="fenris-history-main-page">
        <Header pagename="fenris-landing-page" NavigatedFromChatBot={navigatedFromChatBot} />
        <div className="content-wrapper ">
          <BackgroundVideo/>
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <FenrisMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <FenrisSidebar pagename="fenris-landing-page" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  d-flex  align-items-end text-12 position-relative">
                  <div className="col-12  p-0">
                    <div className="row scan-search-row mx-auto  d-flex  align-items-center">
                      <div className="col-10">
                        <div className="row">
                          <div className="col-3 ">
                            <div className="row text-14  ">
                              {/* <p className="text-16 fw-bold">Prospect</p> */}
                              <div className="col-12">
                                <div className="p-field-checkbox d-flex align-items-center">
                                  <label htmlFor="createNew">Search Existing Client</label>
                                  <Toggle id="useExisting" isChecked={existingInsuredSearch} onChange={handleExistingInsuredSearchToggle} />{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="row text-14 ">
                              {/* <p className="text-16 fw-bold ">Quote</p> */}
                              <div className="col-12">
                                <div className="p-field-checkbox d-flex align-items-center">
                                  <label htmlFor="createNew">Create New Client</label>
                                  <Toggle
                                    id="useExisting"
                                    // className=""
                                    isChecked={newInsuredSearch}
                                    onChange={handleNewInsuredSearchToggle}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-2 text-end ">
                        <AtkButton label="Next" onClick={onNextButtonClick} className="pf-secondary-btn w-100 " />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 text-end mt-3">
                    <AtkButton label="Next" onClick={onNextButtonClick} className="pf-secondary-btn w-auto my-3" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
