import React, { FC, useEffect, useState, useRef } from "react";
import AtkTextField from "../../../AtlaskitControls/AtkTextField";
import AtkButton from "../../../AtlaskitControls/AtkButton";
import CommonValues, { Logout } from "../../../common/utils";
import AtkMessage from "../../../AtlaskitControls/AtkMessage";
import AtkLoader from "../../../AtlaskitControls/AtkLoader";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";
export default function GotoSetting(props: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [gotoPfLoading, setGotoPfLoading] = useState(false);
  const [deleteGotoPfLoading, setDeleteGotoPfLoading] = useState(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [errorMessagegotoUserName, setErrorMessagegotoUserName] = useState("");
  const [gotoPassword, setGotoPassword] = useState("");
  const [gotoUserName, setgotoUserName] = useState("");
  const toast: any = useRef("");
  const [showIGCredsMsg, setShowIGCredsMsg] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [preferredVendorLoading, setPreferredVendorLoading] = useState(false);
  const hostname = window.location.hostname;

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getGotoPfCreds();
  }, []);

  const getGotoPfCreds = () => {
    setLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/input1creds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.status == 204) {
          setGotoPassword("");
          setgotoUserName("");
          setShowIGCredsMsg(true);
        } else {
          setLoading(false);
          setShowIGCredsMsg(false);
          setGotoPassword(response.data.password);
          setgotoUserName(response.data.username);
          if (
            response.data.username !== null &&
            response.data.username !== "" &&
            response.data.userType !== null &&
            response.data.userType !== "" &&
            response.data.portfolioCode !== null &&
            response.data.portfolioCode !== "" &&
            response.data.accountType !== null &&
            response.data.accountType !== "" &&
            response.data.password === ""
          ) {
            setGotoPassword("*********");
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while loading the gotoPremiumFinance Setting details" });
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while loading the gotoPremiumFinance Setting details" });
        }
        setLoading(false);
      });
  };
  const GotoPfCheckValidation = () => {
    let formIsValid = true;
    setErrorMessagePassword("");
    setErrorMessagegotoUserName("");
    if (gotoPassword == "" || gotoPassword == null) {
      formIsValid = false;
      setErrorMessagePassword("Please enter password");
    }

    if (gotoUserName == "" || gotoUserName == null) {
      formIsValid = false;
      setErrorMessagegotoUserName("Please enter user name");
    }

    return formIsValid;
  };
  const getGotopfDetailsForLogin = () => {
    setLoginLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/gotopfcreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setLoginLoading(false);
        window.open(`${response.data}`, "_blank");
      })
      .catch((error) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while loading the gotoPremiumFinance Setting details" });
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while loading the gotoPremiumFinance Setting details" });
        }
        setLoginLoading(false);
      });
  };
  const onUpdateGotoPfClick = () => {
    if (GotoPfCheckValidation()) {
      setGotoPfLoading(true);
      let token = CommonValues.GetToken();
      var data = JSON.stringify({
        Password: gotoPassword,
        Username: gotoUserName,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/input1creds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setGotoPfLoading(false);
          toast.current.show({ severity: "success", detail: "gotoPremiumFinance Settings updated successfully", life: 3000 });
          getGotoPfCreds();
        })
        .catch((error: any) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              navigate("/");
              CommonValues.Logout(navigate);
            } else if (error.response.status == 409) {
              toast.current.show({
                severity: "error",

                detail: "It look likes you are not registered with gotoPremiumFinance, Please register first.",
              });
            } else {
              toast.current.show({ severity: "error", detail: "Unknown error while updating the gotoPremiumFinance Settings" });
            }
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while updating the gotoPremiumFinance Settings" });
          }
          setGotoPfLoading(false);
        });
    }
  };
  const onDeleteGotoPfClick = () => {
    setErrorMessagegotoUserName("");
    setErrorMessagePassword("");
    setDeleteGotoPfLoading(true);
    let token = CommonValues.GetToken();
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/input1creds`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response: any) => {
        toast.current.show({ severity: "success", detail: "gotoPremiumFinance Settings deleted successfully", life: 3000 });
        setGotoPassword("");
        setgotoUserName("");
        setDeleteGotoPfLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while deleting the gotoPremiumFinance Settings" });
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while deleting the gotoPremiumFinance Settings" });
        }
        setDeleteGotoPfLoading(false);
      });
  };
  const onRegisterClick = () => {
    window.open(
      "https://www.gotopremiumfinance.com/Portals/0/Documents/gotoPremiumFinance-Broker-Application.pdf?ver=2020-10-21-132653-357",
      "_blank"
    );
  };
  const onLoginClick = () => {
    getGotopfDetailsForLogin();
  };
  const onKeyUpdateGotoPfClick = (e: any) => {
    if (e.keyCode == 13) {
      onUpdateGotoPfClick();
    }
  };
  const ongotoUserNameChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setgotoUserName(e.target.value);
  };
  const onPasswordChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setGotoPassword(e.target.value);
  };

  const onShowPassword = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const insertPreferredVendor = (vendorId: any, isChecked: any) => {
    setPreferredVendorLoading(true);
    let token = CommonValues.GetToken();
    var data = JSON.stringify({
      ProviderId: vendorId,
      IsChecked: !isChecked,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/preferredvendor`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        // toast.current.show({ severity: "success", detail: "Capital Settings updated successfully", life: 3000 });
        props.getVendorListFunction();
        setPreferredVendorLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setPreferredVendorLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while updating the preferred vendor" });
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while updating the  preferred vendor" });
        }
      });
  };
  return (
      <>
        <Toast ref={toast} />
      <div className="row mx-auto">
        <div className="col-12">
          <div className="pf-setting-col">
            <h4 className="pf-setting-title">
              gotoPremiumFinance
              {hostname === process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2 ? (
                <>
                  {preferredVendorLoading ? (
                    <AtkLoader></AtkLoader>
                  ) : (
                    <input
                      type="checkbox"
                      value=""
                      checked={props.gotoPremiumFinanceVendor}
                      onChange={() => insertPreferredVendor(1, props.gotoPremiumFinanceVendor)}
                      className="show-cursor bundle-checkbox "
                    />
                  )}
                </>
              ) : null}
            </h4>
            <div
              className={
                loading
                  ? "row pf-setting-inputs  mx-auto div-blur-loading d-flex align-items-center"
                  : "row pf-setting-inputs  mx-auto d-flex align-items-center"
              }
            >
              <>
                <div className="col-4 d-flex align-items-center mt-2">
                  <label className="form-label">
                    User Name
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div className={errorMessagegotoUserName.length > 0 ? "col-8 mt-2 validation-div" : "col-8 mt-2 "}>
                  <AtkTextField
                    type="text"
                    id="gotoUserName"
                    name="gotoUserName"
                    onChange={ongotoUserNameChange}
                    value={gotoUserName}
                    onKeyDown={onKeyUpdateGotoPfClick}
                    className="input-field"
                    placeholder="Enter user name"
                  />
                  <span className="text-danger text-12 error-message">{errorMessagegotoUserName}</span>
                </div>

                <div className="col-4 d-flex align-items-center mt-2 ">
                  <label className="form-label mb-0">
                    Password
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div className={errorMessagePassword.length > 0 ? "col-8 mt-2 validation-div position-relative" : "col-8 mt-2 position-relative"}>
                  <AtkTextField
                    type={passwordType}
                    id="gotoPassword"
                    name="gotoPassword"
                    onChange={onPasswordChange}
                    value={gotoPassword}
                    onKeyDown={onKeyUpdateGotoPfClick}
                    className="input-field"
                    placeholder="Enter password"
                    autoComplete="new-password"
                  />
                  <span className="eye-icon" onClick={onShowPassword}>
                    {passwordType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
                  </span>
                </div>
                {errorMessagePassword.length > 0 ? (
                  <>
                    <div className="col-4  "></div>
                    <div className=" col-8  ">
                      <span className="text-danger text-12">{errorMessagePassword}</span>
                    </div>
                  </>
                ) : null}
                <div className="col-12 mb-2 mt-2">
                  {showIGCredsMsg ? <AtkMessage appearance="information" messageText="Using InsuranceGIG Credentials" /> : null}
                </div>
              </>
            </div>
            <div className="row mx-auto  setting-btn-position ">
              <div className="col-4 mt-3 mb-1">
                {deleteGotoPfLoading ? (
                  <AtkLoader></AtkLoader>
                ) : (
                  <AtkButton label="Reset" onClick={onDeleteGotoPfClick} className="pf-primary-btn  w-100" />
                )}
              </div>
              <div className="col-4 mt-3 mb-1">
                {gotoPfLoading ? <AtkLoader></AtkLoader> : <AtkButton label="Save" onClick={onUpdateGotoPfClick} className="pf-primary-btn  w-100" />}
              </div>
              <div className="col-4 mt-3 mb-1">
                <AtkButton label="Register" onClick={onRegisterClick} className="pf-primary-btn  w-100" />
              </div>
              <div className="col-12 mt-2  mb-2">
                {loginLoading ? <AtkLoader></AtkLoader> : <AtkButton label="Login" onClick={onLoginClick} className="pf-secondary-btn  w-100" />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}