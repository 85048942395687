import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import Header from "../../common/Header";
import CommonValues from "../../common/utils";
import ExistingAndFenrisData from "./ExistingAndFenrisData";
import { Toast } from "primereact/toast";
import FenrisMiddleHeader from "./FenrisMiddleHeader";
import FenrisSidebar from "./FenrisSidebar";
import AtkButton from "../../AtlaskitControls/AtkButton";
import { Dropdown } from "primereact/dropdown";
import BackgroundVideo from "../../common/BackgroundVideo";

export default function SearchNewInsuredPage(props: any) {
  const toast: any = useRef("");
  const navigate = useNavigate();
  const [currentBalance, setCurrentBalance] = useState("0");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [clientName, setClientName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [cityName, setCityName] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [insuredId, setInsuredId] = useState("");
  const [showExistingAndFenrisData, setShowExistingAndFenrisData] = useState(false);
  const [currentBalanceUpdateFlag, setCurrentBalanceUpdateFlag] = useState(false);
  const [enrichDataFlag, setEnrichDataFlag] = useState(false);
  const [enrichButtonLoading, setEnrichButtonLoading] = useState(false);
  const [clientNameErrorMessage, setClientNameErrorMessage] = useState("");
  const [addressErrorMessage, setAddressErrorMessage] = useState("");
  const [cityErrorMessage, setCityErrorMessage] = useState("");
  const [stateErrorMessage, setStateErrorMessage] = useState("");
  const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState("");
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
    getCardDetails();
  }, []);

  const onBackClick = () => {
    navigate("/fenrisprefill");
  };

  const getCardDetails = () => {
    setCurrentBalanceLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success == true) {
          setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };

  const onChangeClientName = (value: any) => {
    setClientNameErrorMessage("")
    let inputValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
    const Value = inputValue.slice(0, 255);
    setClientName(Value);
  };
  const onChangeAddressLine1 = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
    const Value = inputValue.slice(0, 255);
    setAddressLine1(Value);
    setAddressErrorMessage("");
  };
  const onChangeCity = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setCityName(Value);
    setCityErrorMessage("");
  };
  const stateDropdownClicked = (e: any) => {
    setState(e.value);
    setStateErrorMessage("");
  };
  const onChangeZipCode = (value: any) => {
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      if (value.length > 5) {
        return;
      }
      setZipCode(value)
    }
    setZipCodeErrorMessage("")
  };

  const newInsuredCreate = async () => {
    try {
      setEnrichButtonLoading(true);
      var token = CommonValues.GetToken();
      var ncToken = CommonValues.GetNCToken();
      var data = JSON.stringify({
        commercialName: clientName,
        addressLine1: addressLine1,
        addressLine2: "",
        state: state,
        city: cityName,
        zipCode: zipCode,
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/createinsured?nowCertsToken=${ncToken}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);
      let insuredInfo = response.data.database_id;
      toast.current.show({
        severity: "success",
        detail: "Client has been created successfully in NowCerts",
        life: 3000,
      });
      setEnrichButtonLoading(false);
      return insuredInfo;
    } catch (error: any) {
      console.log(error);
      let errorMessage = "";
      if (error.response != null) {
        if (error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else if (error.response.status == 400) {
          errorMessage = error.response.errors;
        } else {
          toast.current.show({
            severity: "error",
            detail: "Please correct NowCerts username and password",
            life: 3000,
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          detail: "Unknown error while creating client inside the NowCerts",
          life: 3000,
        });
      }
      setEnrichButtonLoading(false);
      let insuredInfo = "";
      return insuredInfo;
    }
  };

  const onClickEnrichButton = async () => {
    if (checkValidation()) {
      const insuredDatabaseId: any = await newInsuredCreate();
      setInsuredId(insuredDatabaseId);
      setEnrichDataFlag(!enrichDataFlag);
      setShowExistingAndFenrisData(true);
    }
  };

  const updateCurrentBalanceUpdateFlag = () => {
    setCurrentBalanceUpdateFlag(!currentBalanceUpdateFlag);
  };

  const balanceCheck = () => {
    getCardDetails();
  };

  const Allstate = [
    { label: "Select state", value: "" },
    { label: "Alaska", value: "AK" },
    { label: "Alabama", value: "AL" },
    { label: "Arkansas", value: "AR" },
    { label: "Arizona", value: "AZ" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "District of Columbia", value: "DC" },
    { label: "Delaware", value: "DE" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Hawaii", value: "HI" },
    { label: "Iowa", value: "IA" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Massachusetts", value: "MA" },
    { label: "Maryland", value: "MD" },
    { label: "Maine", value: "ME" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Missouri", value: "MO" },
    { label: "Mississippi", value: "MS" },
    { label: "Montana", value: "MT" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Nebraska", value: "NE" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "Nevada", value: "NV" },
    { label: "New York", value: "NY" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Virginia", value: "VA" },
    { label: "Vermont", value: "VT" },
    { label: "Washington", value: "WA" },
    { label: "Wisconsin", value: "WI" },
    { label: "West Virginia", value: "WV" },
    { label: "Wyoming", value: "WY" },
  ];

  const checkValidation = () => {
    let isValid = true;
    if (clientName == null || clientName == undefined || clientName == "") {
      isValid = false;
      setClientNameErrorMessage("Please enter client name.");
    }else{
      setClientNameErrorMessage("");
    } 
    if (addressLine1 == null || addressLine1 == undefined || addressLine1 == "") {
      setAddressErrorMessage("Please enter address line1.");
      isValid = false;
    } else {
      setAddressErrorMessage("");
    } 
    if (cityName == null || cityName == undefined || cityName == "") {
      setCityErrorMessage("Please enter city.");
      isValid = false;
    } else {
     setCityErrorMessage("");
    }if (state == null || state == undefined || state == "") {
      setStateErrorMessage("Please enter state.");
      isValid = false;
    } else {
      setStateErrorMessage("");
    } if (zipCode == null || zipCode == undefined || zipCode == "") {
      setZipCodeErrorMessage("Please enter zip Code.");
      isValid = false;
    } else {
      setZipCodeErrorMessage("");
    }
    return isValid
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="fenris-history-main-page">
        <Header pagename="fenris-landing-page" />
        <div className="content-wrapper ">
          <BackgroundVideo/>
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <FenrisMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <FenrisSidebar pagename="fenris-landing-page" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row client-search-row d-flex align-items-center">
                  <div className="col-2 my-1 ">
                    <span>
                      {" "}
                      <AtkButton label="Back" onClick={onBackClick} className="policy-back-btn w-auto  me-3" />
                    </span>
                  </div>

                  <div className="form-group col-lg-10 col-md-10 col-sm-12 mb-2">
                    <div className="row w-100 d-flex align-items-center justify-content-end">
                      <div className="col-7"></div>
                      <div className="col-3 text-center balance-span">
                        <label>Current Balance</label>
                        {currentBalanceLoading ? (
                          <span className="balance-spinner d-flex  align-items-center justify-content-center ">
                            <AtkLoader />
                          </span>
                        ) : (
                          <span className=" d-block w-auto ">${currentBalance}</span>
                        )}
                        <small className="d-block w-auto text-danger mt-0">80 cent will be charged per usage</small>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2"></div>
                  <div className="col-12 divider"></div>

                  <div className="row history-search-row d-flex  align-items-center text-12 ">
                  <div className={`col-lg-2 col-md-2 col-sm-12 mb-2 ${clientNameErrorMessage.length > 0 ? " validation-div " : ""}`}>
                    <p className="text-14 fw-bold mb-1 ">Enter client name <span className="text-danger ">*</span></p>
                    <AtkTextField
                      className="input-field"
                      type="text"
                      id="text"
                      placeholder="Enter client name"
                      value={clientName}
                      onChange={(e: any) => {
                        onChangeClientName(e.target.value);
                      }}
                    />
                  </div>
                  <div className={`col-lg-2 col-md-2 col-sm-12 mb-2 ${addressErrorMessage.length > 0 ? "validation-div" : ""}`}>
                    <p className="text-14 fw-bold mb-1 ">Address Line 1 <span className="text-danger ">*</span></p>
                    
                    <AtkTextField
                      className="input-field"
                      type="text"
                      id="text"
                      placeholder="Enter address line 1"
                      value={addressLine1}
                      onChange={(e: any) => {
                        onChangeAddressLine1(e.target.value);
                      }}
                    />
                  </div>
                  <div className={`col-lg-2 col-md-2 col-sm-12 mb-2 ${cityErrorMessage.length > 0 ? "validation-div" : ""}`}>
                    <p className="text-14 fw-bold mb-1 ">City <span className="text-danger ">*</span></p>
                    
                    <AtkTextField
                      className="input-field"
                      type="text"
                      id="text"
                      placeholder="Enter city"
                      value={cityName}
                      onChange={(e: any) => {
                        onChangeCity(e.target.value);
                      }}
                    />
                  </div>
                  <div className={`col-lg-2 col-md-2 col-sm-12 mb-2 ${stateErrorMessage.length > 0 ? "validation-div" : ""}`}>
                    <p className="text-14 fw-bold mb-1 ">State <span className="text-danger ">*</span></p>
                    <Dropdown
                      type="text"
                      id="text"
                      className="finance-company-dropdown input-field"
                      value={state}
                      placeholder="Select state"
                      onChange={(e: any) => stateDropdownClicked(e)}
                      options={Allstate}
                    />
                  </div>
                  <div className={`col-lg-2 col-md-2 col-sm-12 mb-2 ${zipCodeErrorMessage.length > 0 ? "validation-div" : ""}`}>
                  <p className="text-14 fw-bold mb-1 ">Zip Code <span className="text-danger ">*</span></p>
                    
                    <AtkTextField
                      className="input-field"
                      type="text"
                      id="text"
                      placeholder="Enter zip code"
                      value={zipCode}
                      onChange={(e: any) => {
                        onChangeZipCode(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-12 mt-2">
                    <AtkButton label="Enrich Rating Data" className="pf-secondary-btn w-auto" onClick={() => onClickEnrichButton()} />
                  </div>
                  <div className="col-2"><span className="text-12 text-danger">{clientNameErrorMessage}</span></div>
                  <div className="col-2"><span className="text-12 text-danger">{addressErrorMessage}</span></div>
                  <div className="col-2"><span className="text-12 text-danger">{cityErrorMessage}</span></div>
                  <div className="col-2"><span className="text-12 text-danger">{stateErrorMessage}</span></div>
                  <div className="col-2"><span className="text-12 text-danger">{zipCodeErrorMessage}</span></div>
                  </div>

                  
                </div>
                <div className="row p-2 mx-auto">
                  {enrichButtonLoading ? (
                    <>
                      <div className="d-flex justify-content-center align-items-center p-1">
                        <AtkLoader></AtkLoader>{" "}
                      </div>
                    </>
                  ) : (
                    <>
                      {showExistingAndFenrisData ? (
                        <div className="col-12">
                          <ExistingAndFenrisData
                            insuredId={insuredId}
                            enrichDataFlag={enrichDataFlag}
                            updateCurrentBalanceUpdateFlag={updateCurrentBalanceUpdateFlag}
                            balanceCheck={balanceCheck}
                          />
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
