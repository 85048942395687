import { FC, useEffect, useState, useRef } from "react";
import Footer from "../common/Footer";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import Header from "../common/Header";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import groupImg from ".././assets/images/Group.png";
import InfoIcon from "@atlaskit/icon/glyph/info";
import { useParams } from "react-router-dom";
import axios from "axios";
import CommonValues from "../common/utils";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import BackgroundVideo from "../common/BackgroundVideo";

export default function Setpassword() {
  const toast: any = useRef("");
  const navigate = useNavigate();
  const [workEmail, setWorkEmail] = useState("");
  const [workEmailError, setWorkEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [confPasswordError, setConfPasswordError] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [inValidRecord, setInValidRecord] = useState(false);
  const { index } = useParams<any>();
  const [tokenValue, setTokenValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  useEffect(() => {
    if (index) {
      setTokenValue(index);
    }
    getDetails(index);
  }, []);

  const getDetails = (token: any) => {
    setLoading(true);

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_AUTHAPI}/api/account/accounttoactivate?token=${token}`,
      headers: {},
    };

    axios(config)
      .then((response: any) => {
        if (response.data.length > 0) {
          setWorkEmail(response.data[0].workEmail);
          setLoading(false);
          setInValidRecord(false);
        } else {
          setLoading(false);
          setInValidRecord(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.current.show({ severity: "error", detail: "Unknow error while validating the token!", life: 3000 });
        console.log(error);
      });
  };
  const onWorkemailChange = (e: any) => {
    const value = e.target.value;
    const regex =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (value.length > 255) {
      return;
    }

    setWorkEmail(value);
    if (!value.trim()) {
      setWorkEmailError("Please enter work email");
    } else if (regex.test(value) === false) {
      setWorkEmailError("Please enter a valid work email");
    } else {
      setWorkEmailError("");
    }
  };
  const onPasswordChange = (e: any) => {
    let limitedValue = e.target.value.slice(0, 16);
    const regix = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");

    setPassword(limitedValue);
    if (!e.target.value.trim()) {
      setPasswordError("Please enter  password");
    } else if (regix.test(limitedValue) == false) {
      setPasswordError("Please enter a valid password");
    } else {
      setPasswordError("");
    }
  };
  const onConfPasswordChange = (e: any) => {
    let limitedValue = e.target.value.slice(0, 16);
    setConfPassword(limitedValue);
    if (!e.target.value.trim()) {
      setConfPasswordError("Please enter confirm password");
    } else if (password != e.target.value) {
      setConfPasswordError("Password did not match");
    } else {
      setConfPasswordError("");
    }
  };
  const onShowPassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const onShowConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };
  const onForgotPasswordLinkClicked = () => {
    navigate("/forgotpassword");
  };
  const checkValidation = () => {
    let formIsValid = true;
    setPasswordError("");
    setConfPasswordError("");
    setWorkEmailError("");
    const regix = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    if (workEmail == "" || workEmail == null) {
      setWorkEmailError("Please enter work email");
      formIsValid = false;
    }
    if (password == "" || password == null) {
      setPasswordError("Please enter  password");
      formIsValid = false;
    } else if (regix.test(password) == false) {
      setPasswordError("Please enter a valid password");
      formIsValid = false;
    }
    if (confPassword == "" || confPassword == null) {
      setConfPasswordError("Please enter confirm password");
      formIsValid = false;
    }
    if (password != confPassword) {
      setConfPasswordError("Password did not match");
      formIsValid = false;
    }

    return formIsValid;
  };
  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      onSetPasswordClicked();
    }
  };
  const onSetPasswordClicked = () => {
    if (checkValidation()) {
      var data = JSON.stringify({
        UserName: workEmail,
        Password: password,
        Token: tokenValue,
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_AUTHAPI}/api/account/activateaccount`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      setSubmitLoading(true);
      axios(config)
        .then((response) => {
          setSubmitLoading(false);
          toast.current.show({ severity: "success", detail: "Account activated successfully!", life: 3000 });

          setTimeout(() => {
            let loginResponse = response.data;

            if (loginResponse.isAuthenticated) {
              CommonValues.SetValues(
                response.data.token,
                response.data.tenantId,
                response.data.userName,
                response.data.userId,
                response.data.companyName,
                response.data.roleId,
                response.data.avatarName,
                response.data.firstName,
                "",
                response.data.iconURL,
                response.data.hasGIGMarket,
                response.data.slugURL,
                response.data.headerText
              );
            }
            getWizardSetupDetails();
          }, 1000);
        })

        .catch((error) => {
          console.log(error);
          setSubmitLoading(false);
          toast.current.show({ severity: "error", detail: "Unknown error while activating the account!", life: 3000 });
        });
    }
  };
  const getWizardSetupDetails = () => {
    var token = CommonValues.GetToken();
    setLoading(true);

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/getwizardsetup`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setLoading(false);

        if (response.status == 204) {
          insertWizardDetails(false);
        } else {
          if (response.data.isWizardSetupDone) {
            navigate("/landingpage");
            localStorage.setItem("isSetupCompleted", "true");
          } else {
            navigate("/setupwizard");
            localStorage.setItem("isSetupCompleted", "false");
          }
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        setLoading(false);

        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while loading the Setting details", life: 3000 });
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while loading the Setting details", life: 3000 });
        }
        // toast.current.show({ severity: "error",  detail: { errorMessage }, life: 3000 });
      });
  };
  const insertWizardDetails = (val: any) => {
    var token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/insertwizardsetup?IsWizardSetupDone=${val}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setLoading(false);
        if (response.data.isWizardSetupDone) {
          navigate("/landingpage");
          localStorage.setItem("isSetupCompleted", "true");
        } else {
          navigate("/setupwizard");
          localStorage.setItem("isSetupCompleted", "false");
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        setLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
            toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
          toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
        }
      });
  };
  return (
    <div className="login-page">
      <Toast ref={toast} />
      <Header pagename="setpassword" />
      
      <div className="content-wrapper container-fluid landing-content-wrapper d-flex justify-content-center">
      <BackgroundVideo/>
        <div className="register-box">
          <div className="row register-header mx-auto p-3">
            <div className="col-12 ">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-12 d-flex  align-items-center">
                  <img src={groupImg} className="me-2" />
                  <span className="text-16">
                    <b>Set Password</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="d-flex align-items-center justify-content-center success-box">
              <AtkLoader />
            </div>
          ) : (
            <>
              {inValidRecord ? (
                <div className="row mx-auto success-box">
                  <div className="col-12 already-user-exist-message ">
                    <p className="d-flex justify-content-center align-items-center">
                      <span>
                        Sorry, but this link is no longer valid.
                        <br /> To set your GigMarket password, please follow this{" "}
                        <a className="registration-related-link" onClick={onForgotPasswordLinkClicked}>
                          {" "}
                          link
                        </a>
                        .
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="row mx-auto d-flex align-items-center px-3 py-4">
                  <div className=" col-4 text-start mt-3">
                    <label>
                      Work Email<span className="text-danger "> *</span>{" "}
                    </label>
                  </div>
                  <div className={workEmailError.length > 0 ? "col-8  validation-div mt-3" : "col-8 mt-3"}>
                    <AtkTextField
                      type="text"
                      id="txtWorkEmail"
                      name="workEmail"
                      onChange={onWorkemailChange}
                      value={workEmail}
                      className="input-field"
                      placeholder="Enter work email"
                      isDisabled={true}
                    />
                  </div>
                  <div className=" col-4 text-start "></div>
                  <div className="col-8 ">
                    <span className="text-danger text-12 ">{workEmailError}</span>
                  </div>
                  <div className=" col-4 text-start mt-3">
                    <label>
                      Password<span className="text-danger "> *</span>{" "}
                    </label>
                  </div>
                  <div className={passwordError.length > 0 ? "col-8  validation-div mt-3  position-relative " : "col-8 mt-3  position-relative "}>
                    <AtkTextField
                      type={passwordType}
                      id="txtpassword"
                      name="password"
                      onChange={onPasswordChange}
                      value={password}
                      className="input-field"
                      placeholder="Enter password"
                      onKeyDown={onEnterClick}
                    />
                    <span className="login-eye-icon show-cursor" onClick={onShowPassword}>
                      {passwordType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
                    </span>{" "}
                  </div>
                  <div className=" col-4 text-start "></div>
                  <div className="col-8 ">
                    <span className="text-danger text-12 ">{passwordError}</span>
                  </div>
                  <div className="col-sm-4"></div>
                  <div className="col-sm-8 profile-info-icon">
                    <div className="row">
                      <div className="col-lg-1 col-md-2  col-sm-3 col-2">
                        <InfoIcon label="" />
                      </div>
                      <div className="col-lg-11 col-md-10  col-sm-9 col-10 p-0">
                        <span className="d-block">Minimum Length 8 Characters</span>
                        <span className="d-block">1 Special Character | 1 Lower Case Letter</span>
                        <span className="d-block">1 Upper Case Letter | 1 Number</span>
                        <span className="d-block">e.g: Abc#15@6</span>
                      </div>
                    </div>
                  </div>

                  <div className=" col-4 text-start mt-3">
                    <label>
                      Confirm Password<span className="text-danger "> *</span>{" "}
                    </label>
                  </div>
                  <div className={confPasswordError.length > 0 ? "col-8  validation-div mt-3 position-relative" : "col-8 mt-3 position-relative"}>
                    <AtkTextField
                      type={confirmPasswordType}
                      id="txtconfPassword"
                      name="confPassword"
                      onChange={onConfPasswordChange}
                      value={confPassword}
                      className="input-field"
                      placeholder="Enter confirm password"
                      onKeyDown={onEnterClick}
                    />
                    <span className="login-eye-icon show-cursor" onClick={onShowConfirmPassword}>
                      {confirmPasswordType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
                    </span>{" "}
                  </div>
                  <div className=" col-4 text-start "></div>
                  <div className="col-8 ">
                    <span className="text-danger text-12 ">{confPasswordError}</span>
                  </div>

                  <div className="col-12 text-center my-3">
                    {submitLoading ? (
                      <div className="p-1">
                        <AtkLoader />{" "}
                      </div>
                    ) : (
                      <AtkButton label="Set Password" onClick={onSetPasswordClicked} className="pf-primary-btn w-auto" />
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
}
