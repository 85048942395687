import { useEffect, useState } from "react";
import CommonValues from "../../common/utils";
import ResponseButton from "./ResponseButton";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import { useNavigate } from "react-router-dom";
import FinalQuotes from "./FinalQuotes";
import { Skeleton } from 'primereact/skeleton';
import axios from "axios";

export default function FirstInsuranceFunding(props: any) {
  const [insuranceLoading, setFInsuranceLoading] = useState(false);
  const [firstInsuranceFundingShowResponse, setFirstInsuranceFundingShowResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [saveQuoteErrorMessage, setSaveQuoteErrorMessage] = useState("");
  const [saveQuoteSuccessMessage, setSaveQuoteSuccessMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [rowData, setRowData] = useState({});
  const [firstInsuranceFundingPremiumFinanceResponse, setFirstInsuranceFundingPremiumFinanceResponse] = useState<any>({});

  const navigate = useNavigate();
  useEffect(() => {  
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }

    firstInsuranceFundingSubmitClick();
  }, []);
  const firstInsuranceFundingSubmitClick = () => {
  
    let subPolicies: any = [];
    // let term = "";
    // term = props.term.toString();
    // if (term.includes("M")) {
    //   term = term.replace("M", "");
    // }

    var token = CommonValues.GetToken();

    Array.from(props.coverageCode).forEach((code: any, index: any) => {
      subPolicies.push({
        number: props.policyNumber[index],
        coverageCode: code.firstInsuranceCode == null ? "*" : code.firstInsuranceCode,
        policyId: props.nCPolicyId,
        effectiveDate: props.effectiveDate[index],
        expirationDate: "",
        term: props.term[index],
        BrokerFee: props.brokerFee[index] == null || props.brokerFee[index] === "" ? "0" : props.brokerFee[index],
        premium: props.currentPremiumsArray[index] ? props.currentPremiumsArray[index].toString() : 0,
        earnedTaxesFees: "0",
        financedTaxesFees: "0",
        otherFees: props.fees[index] != undefined ? props.fees[index].toString() : "0",
        otherTaxes: props.taxes[index] != undefined ? props.taxes[index].toString() : "0",
        minimumEarnedPercent: props.minimumEarned[index] != undefined ? props.minimumEarned[index] : "0",
        policyType: "commercial",
        index: props.selectedPolicyIndex ? props.selectedPolicyIndex : 0,
        carrier: {
          id: props.carrier[index] ? props.carrier[index].id.toString() : "",
          name: props.carrier[index].description,
          address: {
            address1: props.carrierAddress[index],
            address2: "",
            city: props.carrierCity[index],
            state: props.carrierState[index],
            zip: props.carrierZip[index],
            country: props.carrierCountry,
            unit: "",
          },
        },
        generalAgency: {
          id: props.gaId[index],
          name: props.gaName[index],
          address: {
            address1: props.gaAddress[index],
            address2: "",
            city: props.gaCity[index],
            state: props.gaState[index],
            zip: props.gaZip[index],
            country: props.gaCountry[index],
            unit: "",
          },
        },
      });
    });

    var data = JSON.stringify({
      target: "PFINSITE",
      data: {
        ClientName: props.firstName + " " + props.lastName,
        ClientId: props.clientId.toString(),
        IsIndicative: localStorage.getItem("IsIndicative"),        
        insured: {
          insuredId: props.nCInsuredId,
          name: props.businessProfile,
          dbaName: "",
          naics: "",
          phoneNumber: props.businessPhoneNumber != "" ? props.businessPhoneNumber : "000",
          email: props.businessEmail,
          address: {
            address1: props.addLine1,
            address2: props.addLine2,
            city: props.city,
            state: props.sstate,
            zip: props.zipCode,
            country: props.country,
            unit: "",
          },
        },
        agent: {
          id: "",
          firstName: props.agentName,
          lastName: "",
          email: "",
          phoneNumber: props.agentPhoneNumber,
          address: {
            address1: props.agentAddress,
            address2: "",
            city: props.agentCity,
            state: props.agentState,
            zip: props.agentZip,
            country: props.agentCountry,
            unit: "",
          },
        },
        policy: subPolicies,
      },
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/quote`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    setFInsuranceLoading(true);
    axios(config)
      .then((response: any) => {
        window.scroll(0, 0);

        setFirstInsuranceFundingPremiumFinanceResponse(response.data);
        if (response.data.quoteNumber == null || response.data.quoteNumber == "" || response.data.quoteNumber == "0" || response.status == 204) {
          setFirstInsuranceFundingShowResponse(true);
          setResponseMessage(response.data.message);

        } else {
            setFirstInsuranceFundingShowResponse(false);
        }
        let responseData = {
            transactionId: response.data.transactionId,
            url: "",
            provider: "First Insurance Funding",
            targetPartner: "First Insurance Funding",
            isSaved: false,
            quoteNumber: response.data.quoteNumber,
            ncPolicyNumber: props.ncPolicyNumber,
            policyId: props.nCPolicyId,
            insuredId: props.nCInsuredId,
            policyNumber: props.policyNumber,
            clientId: props.clientId,
            selectedPolicyIndex: props.selectedPolicyIndex,
            responseMessage: response.data.message,
            premiumresponse: response.data,
            tenantId:localStorage.getItem("tenantId")
          };
          setRowData(responseData);
        setFInsuranceLoading(false);
        setSuccessMessage("Details submitted successfully..");
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while submitting the details for first insurance funding ";
          }
        } else {
          errorMessage = "Unknown error while submitting  details for first insurance funding";
        }
        setFirstInsuranceFundingShowResponse(true);
        setErrorMessage(errorMessage);
        setResponseMessage(errorMessage);
        setFInsuranceLoading(false);
      });
  };
  return (
    <>
      <div className=" quote-response-block">
        <h4 className="quote-heading">
          <img src={props.firstInsuranceFundingLogoUrl} width="25" height="25" /> FIRST Insurance Funding Quote
        </h4>
        <div className="quote-middle-block">
          {insuranceLoading ? (
           
            <Skeleton width="100%" height="15rem"></Skeleton>
          ) : (
            <>
              {firstInsuranceFundingShowResponse ? (
                <div className="my-2 p-2">
                  <AtkMessage appearance="warning" messageText={responseMessage} />
                </div>
              ) : (
                <>
                  {/* <div className=""> */}
                  <FinalQuotes premiumresponse={firstInsuranceFundingPremiumFinanceResponse} />
                  <div className="response-btn-div  ">
                    <ResponseButton rowData={rowData} />
                  </div>
                  {/* </div> */}
                </>
              )}
            </>
          )}

          {saveQuoteErrorMessage.length > 0 ? (
            <div className="my-2 p-2">
              <AtkMessage appearance="error" messageText={saveQuoteErrorMessage} />
            </div>
          ) : null}
          {saveQuoteSuccessMessage.length > 0 ? (
            <div className="my-2 p-2">
              <AtkMessage appearance="success" messageText={saveQuoteSuccessMessage} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
